import {Client} from "./client-profile";

export type NotificationFrequencyType = "REAL_TIME" | "HOURLY"
  | "THREE_HOURS" | "DAILY";

export const REFERRAL_EVENT = "REFERRAL";
export const CRISIS_EVENT = "CRISIS";
export const HOSPITAL_EVENT = "HOSPITAL";
export const JI_EVENT = "JI";
export const POST_ACUTE_CARE_EVENT = "POST_ACUTE_CARE";
export const FIND_HELP_EVENT = "FIND_HELP";

export type NotificationEventType = typeof REFERRAL_EVENT | typeof CRISIS_EVENT | typeof HOSPITAL_EVENT
  | typeof JI_EVENT | typeof POST_ACUTE_CARE_EVENT | typeof FIND_HELP_EVENT;

export type NotificationEventSettingType =
  HospitalSetting | ReferralSetting | CrisisSetting | JISetting | PacSetting | FindHelpSetting;

export type NotificationFormInfoType = typeof HospitalFormInfo | typeof ReferralFormInfo
  | typeof CrisisFormInfo | typeof JIFormInfo | typeof PacFormInfo | typeof FindHelpFormInfo;

export interface NotificationDto {
  id: number;
  eventType: NotificationEventType
  eventSubtype: string
  clientResponse: Client
  groupName: string
  eventDate: Date
  viewed: boolean
}

export interface NotificationResponse {
  totalElements: number
  notifications: NotificationDto[]
}

export interface NotificationSettingsDto {
  notificationEventSettingDtos: { [key: string]: NotificationEventSettingDto };
  notificationScheduleDto: NotificationScheduleDto | null;
}

export interface NotificationEventSettingDto {
  enabled: boolean;
  eventType: NotificationEventType;
  emailEnabled: boolean;
  smsEnabled: boolean;
  notificationEventSubtypes: { [key: string]: boolean };
}

export interface NotificationScheduleDto {
  fromTime: string
  toTime: string
  timezone: string
  notificationFrequency: string
  customScheduledEnabled: boolean
  notificationScheduleDays: string[]
}
export interface NotificationSettings {
  data: NotificationSettingsDto;
  referralSetting: ReferralSetting
  crisisSetting: CrisisSetting
  findHelpSetting: FindHelpSetting
  hospitalSetting: HospitalSetting
  jiSetting: JISetting
  pacSetting: PacSetting
  notificationSchedule: NotificationSchedule
}

export interface NotificationSetting {
  enabled: boolean
  eventType: NotificationEventType
  emailEnabled: boolean
  smsEnabled: boolean
}

export interface ReferralSetting extends NotificationSetting {
  newReferral: boolean
  accepted: boolean
  declined: boolean
  show: boolean
  noShow: boolean
}

export interface CrisisSetting extends NotificationSetting {
  intakeCompleted: boolean
  dispatchCanceled: boolean
  dispatchRequested: boolean
  dispatchAccepted: boolean
  dispatchDeclined: boolean
  dispatchArrived: boolean
  dispatchDeparted: boolean
  dispatchAcknowledged: boolean
  intakeCanceled: boolean
}

export interface FindHelpSetting extends NotificationSetting {
  newReferral: boolean
  accepted: boolean
  declined: boolean
  pending: boolean
}

export interface HospitalSetting extends NotificationSetting {
  admitted: boolean
  presentedEr: boolean
  discharged: boolean
  transferred: boolean
  deceased: boolean
}

export interface JISetting extends NotificationSetting {
  booked: boolean
  released: boolean
}

export interface PacSetting extends NotificationSetting {
  admitted: boolean
  discharge: boolean
  transferred: boolean
  deceased: boolean
}

export interface NotificationSchedule {
  fromTime: Date
  toTime: Date
  timezone: string
  notificationFrequency: NotificationFrequencyType
  customScheduledEnabled: boolean
  notificationScheduleDays: NotificationScheduleDays
}

export interface NotificationScheduleDays {
  mon: boolean
  tue: boolean
  wed: boolean
  thu: boolean
  fri: boolean
  sat: boolean
  sun: boolean
}

export type NotificationFormInfo = {
  label: string;
  code: string;
}

export type ReferralInfoType = { [K in keyof Partial<ReferralSetting>]: NotificationFormInfo };

export const ReferralFormInfo: ReferralInfoType = {
  newReferral: {
    label: 'New referral',
    code: 'NEW_REFERRAL',
  },
  accepted: {
    label: 'Referral accepted',
    code: 'REFERRAL_ACCEPTED',
  },
  declined: {
    label: 'Referral declined',
    code: 'REFERRAL_DECLINED',
  },
  show: {
    label: 'Client showed',
    code: 'REFERRAL_SHOW',
  },
  noShow: {
    label: 'Client no showed',
    code: 'REFERRAL_NO_SHOW',
  },
};

export type CrisisInfoType = { [K in keyof Partial<CrisisSetting>]: NotificationFormInfo };

export const CrisisFormInfo: CrisisInfoType = {
  intakeCompleted: {
    label: 'Intake completed',
    code: 'CRISIS_INTAKE_COMPLETED',
  },
  intakeCanceled: {
    label: 'Intake canceled',
    code: 'CRISIS_INTAKE_CANCELED',
  },
  dispatchRequested: {
    label: 'Mobile dispatch requested',
    code: 'CRISIS_DISPATCH_REQUESTED',
  },
  dispatchAcknowledged: {
    label: 'Mobile dispatch acknowledged',
    code: 'CRISIS_DISPATCH_ACKNOWLEDGED',
  },
  dispatchAccepted: {
    label: 'Mobile dispatch accepted',
    code: 'CRISIS_DISPATCH_ACCEPTED',
  },
  dispatchDeclined: {
    label: 'Mobile dispatch declined',
    code: 'CRISIS_DISPATCH_DECLINED',
  },
  dispatchArrived: {
    label: 'Mobile dispatch arrived',
    code: 'CRISIS_DISPATCH_ARRIVED',
  },
  dispatchDeparted: {
    label: 'Mobile dispatch departed',
    code: 'CRISIS_DISPATCH_DEPARTED',
  },
  dispatchCanceled: {
    label: 'Mobile dispatch canceled',
    code: 'CRISIS_DISPATCH_CANCELED',
  }
};

export type HospitalInfoType = { [K in keyof Partial<HospitalSetting>]: NotificationFormInfo };

export const HospitalFormInfo: HospitalInfoType= {
  presentedEr: {
    label: 'Presented',
    code: 'PRESENTED',
  },
  admitted: {
    label: 'Admitted',
    code: 'ADMITTED',
  },
  transferred: {
    label: 'Transferred',
    code: 'TRANSFERRED',
  },
  discharged: {
    label: 'Discharged',
    code: 'DISCHARGED',
  },
  deceased: {
    label: 'Deceased',
    code: 'DECEASED',
  },


};

export type JIInfoType = { [K in keyof Partial<JISetting>]: NotificationFormInfo};

export const JIFormInfo: JIInfoType = {
  booked: {
    label: 'Booked',
    code: 'BOOKED',
  },
  released: {
    label: 'Released',
    code: 'RELEASED',
  }
};

export type PacInfoType = { [K in keyof Partial<PacSetting>]: NotificationFormInfo};

export const PacFormInfo: PacInfoType = {
  admitted: {
    label: 'Admitted',
    code: 'ADMITTED',
  },
  discharge: {
    label: 'Discharged',
    code: 'DISCHARGED',
  },
  transferred: {
    label: 'Transferred',
    code: 'TRANSFERRED',
  },
  deceased: {
    label: 'Deceased',
    code: 'DECEASED',
  }
};

export type FindHelpInfoType = { [K in keyof Partial<FindHelpSetting>]: NotificationFormInfo};

export const FindHelpFormInfo: FindHelpInfoType = {
  newReferral: {
    label: 'New referral',
    code: 'NEW_REFERRAL',
  },
  pending: {
    label: 'Referral pending',
    code: 'REFERRAL_PENDING',
  },
  accepted: {
    label: 'Referral accepted',
    code: 'REFERRAL_ACCEPTED',
  },
  declined: {
    label: 'Referral declined',
    code: 'REFERRAL_DECLINED',
  }

};

export type DayOfWeekInfoType = { [K in keyof NotificationScheduleDays]: NotificationFormInfo};

export const dayOfWeekFormInfo: DayOfWeekInfoType = {
  mon: {
    label: 'Mon',
    code: 'MONDAY',
  },
  tue: {
    label: 'Tue',
    code: 'TUESDAY',
  },
  wed: {
    label: 'Wed',
    code: 'WEDNESDAY',
  },
  thu: {
    label: 'Thu',
    code: 'THURSDAY',
  },
  fri: {
    label: 'Fri',
    code: 'FRIDAY',
  },
  sat: {
    label: 'Sat',
    code: 'SATURDAY',
  },
  sun: {
    label: 'Sun',
    code: 'SUNDAY',
  }
};

export type NotificationFrequencyInfoType = {[key in NotificationFrequencyType]: string};

export const notificationFrequencyInfo: NotificationFrequencyInfoType = {
  REAL_TIME: "In Real Time",
  HOURLY: "Every Hour",
  THREE_HOURS: "Every 3 Hours",
  DAILY: "Once Daily"
};

export type NotificationInfo = {
  drawerHeaderText: string,
  profileLabelText: string,
  notificationFieldName: keyof Partial<NotificationSettings>,
  eventFormInfo: NotificationFormInfoType
}

export type NotificationInfoType = { [key in NotificationEventType]: NotificationInfo; };

export const NotificationInfoMap: NotificationInfoType = {
  REFERRAL: {
    drawerHeaderText: 'DTRN eReferral event settings',
    profileLabelText: 'DTRN eReferral events',
    notificationFieldName: 'referralSetting',
    eventFormInfo: ReferralFormInfo
  },
  CRISIS: {
    drawerHeaderText: 'Crisis event settings',
    profileLabelText: 'Crisis events',
    notificationFieldName: 'crisisSetting',
    eventFormInfo: CrisisFormInfo
  },
  HOSPITAL: {
    drawerHeaderText: 'Hospital event settings',
    profileLabelText: 'Hospital events',
    notificationFieldName: 'hospitalSetting',
    eventFormInfo: HospitalFormInfo
  },
  JI: {
    drawerHeaderText: 'Justice Involved event settings',
    profileLabelText: 'Justice Involved events',
    notificationFieldName: 'jiSetting',
    eventFormInfo: JIFormInfo
  },
  POST_ACUTE_CARE: {
    drawerHeaderText: 'Post acute care event settings',
    profileLabelText: 'Post acute care events',
    notificationFieldName: 'pacSetting',
    eventFormInfo: PacFormInfo
  },
  FIND_HELP: {
    drawerHeaderText: 'DTRN Social Care event settings',
    profileLabelText: 'DTRN Social Care events',
    notificationFieldName: 'findHelpSetting',
    eventFormInfo: FindHelpFormInfo
  }
};

export const NotificationDisplayText: { [key in NotificationEventType]: any } = {
  REFERRAL: {
    'NEW_REFERRAL': 'New referral',
    'REFERRAL_ACCEPTED': 'Referral accepted',
    'REFERRAL_DECLINED': 'Referral declined',
    'REFERRAL_SHOW': 'Referral show',
    'REFERRAL_NO_SHOW': 'Referral no show'

  },
  CRISIS: {
    'CRISIS_INTAKE_COMPLETED': 'Crisis intake completed',
    'CRISIS_DISPATCH_CANCELED': 'Crisis dispatch canceled',
    'CRISIS_DISPATCH_REQUESTED': 'Crisis dispatch requested',
    'CRISIS_DISPATCH_ACCEPTED': 'Crisis dispatch accepted',
    'CRISIS_DISPATCH_DECLINED': 'Crisis dispatch declined',
    'CRISIS_DISPATCH_ARRIVED': 'Crisis dispatch arrived',
    'CRISIS_DISPATCH_DEPARTED': 'Crisis dispatch departed',
    'CRISIS_DISPATCH_ACKNOWLEDGED': 'Crisis dispatch acknowledged',
    'CRISIS_INTAKE_CANCELED': 'Crisis intake canceled',

  },
  HOSPITAL: {
    'ADMITTED': 'Hospital admitted',
    'PRESENTED': 'Hospital presented',
    'DISCHARGED': 'Hospital discharged',
    'TRANSFERRED': 'Hospital transferred',
    'DECEASED': 'Hospital deceased'
  },
  JI: {
    'BOOKED': 'Justice Involved booked',
    'RELEASED': 'Justice Involved released'
  },
  POST_ACUTE_CARE: {
    'ADMITTED': 'Post acute admitted',
    'DISCHARGED': 'Post acute discharged',
    'TRANSFERRED': 'Post acute transferred',
    'DECEASED': 'Post acute deceased'
  },
  FIND_HELP: {
    'NEW_REFERRAL': 'New referral',
    'REFERRAL_ACCEPTED': 'Referral accepted',
    'REFERRAL_DECLINED': 'Referral declined',
    'REFERRAL_PENDING': 'Referral pending'
  }
};

export enum NotificationAlert {
  NOTIFICATION_MARKED_AS_READ = 'Notification marked as read.',
  NOTIFICATION_MARKED_AS_UNREAD = 'Notification marked as unread.',
  ALL_NOTIFICATIONS_MARKED_AS_READ = 'All notifications marked as read.',
  FAILED_TO_MARK_NOTIFICATION = 'Failed to mark a notification as read.',
  FAILED_TO_MARK_ALL_NOTIFICATIONS = 'Failed to mark all notifications as read.',
}
