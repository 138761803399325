import {DataSet, Timeline} from "vis-timeline/standalone";
import React, {useEffect, useMemo, useRef, useState} from "react";
import Icon from "patient-ping-remedy/packages/icon";
import {css} from "@emotion/css";
import {colors} from "patient-ping-remedy/packages/theme";
import Helpers from "../../../helpers/helpers";
import {SmallSemiBoldTypography, SmallTypography} from "patient-ping-remedy/packages/typography";
import {BASE_OPTIONS_MONTH, BASE_OPTIONS_WEEK, BASE_OPTIONS_YEAR, ViewOptions, Views} from "./vis_timeline.options";
import {
  VisibleYearLabel,
  VisNavLeft,
  VisNavRight,
  VisTimelineContainer,
} from "./VisTimeline.styles";
import ButtonBar from "patient-ping-remedy/packages/button-bar/lib";
import Loading from "../Loading";
import {ADTItem, ClientEncounter, EncounterType} from "../../../api/dto/client-profile";
import {IntakeDetails} from "../../../api/dto/crisis";
import {MasterReferralResponse} from "../../../api/dto/referral";
import {
  mapAdtItem,
  mapCrisisEvent,
  mapJusticeInvolvedEvent,
  mapReferralEvent,
  mapSdohItem
} from "../../../helpers/timeline_helpers";
import {JusticeInvolvedItem} from "../../../api/dto/justice-involved";
import {SDOHItem} from "../../../api/dto/sdoh";
import InfoIconTooltip from "../InfoIconTooltip";

type TimelineEvent = {
  id: string;
  content: string;
  start: Date;
  end: Date;
  className: string;
  title: string;
};

type DateMap = {
  [key: string]: Date;
}

type Props = {
  encounters: ClientEncounter[];
  horizontalInView: boolean;
  setHorizontalInView: Function;
  isLoading: boolean;
  isPolling: boolean;
};

// documentation: https://visjs.github.io/vis-timeline/docs/timeline/
// examples: https://visjs.github.io/vis-timeline/examples/timeline/
const VisTimeline = (props: Props) => {
  const timelineRef = useRef(null);
  const timeline = useRef<Timeline | null>();
  const timelineData = useRef<DataSet<TimelineEvent>>(new DataSet([]));
  const [showMajorLabels, setShowMajorLabels] = useState<boolean>(false);
  const [visibleYear, setVisibleYear] = useState<number | null>(null);
  const [showGridlines, setShowGridlines] = useState<boolean>(false);
  const [view, setView] = useState<ViewOptions>(Views.WEEK);
  const [dates, setDates] = useState<DateMap | null>(null);
  const [hiddenArrow, setHiddenArrow] = useState<string | null>('right');
  const isLoading = props.isLoading || props.isPolling;

  const infoTooltipContent = useMemo(() => {
    return <>
      <p>
        <SmallSemiBoldTypography>How to use this timeline</SmallSemiBoldTypography>
      </p>
      <p>
        <SmallSemiBoldTypography>Navigation: </SmallSemiBoldTypography>
        <SmallTypography>
          Use the horizontal arrows or click and drag to move forward and backward in time.
          Click the “Go To Today” button to quickly return to today.
        </SmallTypography>
      </p>
      <p>
        <SmallSemiBoldTypography>View Options: </SmallSemiBoldTypography>
        <SmallTypography>
          Change the granularity of your view with the “Week,” “Month,” and “Year” options.
          Show or hide gridlines using the “Show Gridlines” button.
        </SmallTypography>
      </p>
      <p>
        <SmallSemiBoldTypography>Event Details: </SmallSemiBoldTypography>
        <SmallTypography>
          Hover over an event icon to see see group name and start and end dates.
          Click on an event to view its event card, containing additional details, in the Recent Events timeline below.
        </SmallTypography>
      </p>
    </>;
  },[]);

  const initTimeline = () => {
    let initTimeline = new Timeline(timelineRef.current!,
      timelineData.current,
      {
        ...BASE_OPTIONS_WEEK,
        min: dates!.sixMonthsAgo, // lower limit of visible range
        max: dates!.weekInFuture, // upper limit of visible range
      });

    initTimeline.on('click', function (properties) {
      if(properties.item){
        let selectedEventNode = document.getElementById(properties.item);
        if(selectedEventNode) {
          selectedEventNode.scrollIntoView({
            block: 'center', inline: 'center', behavior: 'smooth'
          });
        }
      }
    });

    initTimeline.on('rangechanged', function(properties) {
      setVisibleYear(properties.end.getFullYear());

      if(timeline!.current!.getWindow().end.toLocaleDateString()
        // @ts-ignore
        === timeline!.current!.range.options.max.toLocaleDateString()) {
        setHiddenArrow('right');
      } else if(timeline!.current!.getWindow().start.toLocaleDateString()
        // @ts-ignore
        === timeline!.current!.range.options.min.toLocaleDateString()) {
        setHiddenArrow('left');
      } else {
        setHiddenArrow(null);
      }

      if(timeline.current) {
        if(atEndOfTimeline()) {
          getMoreData();
        }
      }
    });

    initTimeline.setWindow(dates!.threeMonthsAgo, dates!.weekInFuture, {animation: false});

    timeline.current = initTimeline;
  };

  const atEndOfTimeline = () => {
    if(timeline.current) {
      let timelineEnd = timeline!.current!.getWindow().start.toLocaleDateString();
      // @ts-ignore
      let timelineMin = timeline!.current!.range.options.min.toLocaleDateString();
      return timelineEnd === timelineMin;
    }

    return false;
  };

  const move =(percentage: number) => {
    let range = timeline!.current!.getWindow();
    let interval = range.end.valueOf() - range.start.valueOf();
    timeline!.current!.setWindow(range.start.valueOf() - interval * percentage, range.end.valueOf() - interval * percentage);
  };

  const getMoreData = () => {
    // @ts-ignore
    let currentMin = timeline!.current!.range.options.min;
    let newMin = new Date(currentMin);
    newMin.setMonth(newMin.getMonth() - 6);

    timeline!.current!.setOptions({
      min: newMin, // lower limit of visible range
    });

    props.setHorizontalInView(true);
  };

  const jumpToDate = (date: Date) => {
    timeline!.current!.moveTo(date, {
      animation: true
    });
  };

  const changeView = () => {
    let currentWindow = timeline.current!.getWindow();
    setVisibleYear(currentWindow.end.getFullYear());
    let newWindowStart = new Date(currentWindow.end);
    let newWindowEnd = new Date(currentWindow.end);

    if(view === Views.WEEK) {
      timeline.current!.setOptions({
        ...BASE_OPTIONS_WEEK,
      });

      setShowMajorLabels(false);
      newWindowStart.setMonth(currentWindow.end.getMonth() - 3);
    } else if(view === Views.MONTH) {
      timeline.current!.setOptions({
        ...BASE_OPTIONS_MONTH,
      });

      setShowMajorLabels(true);
      newWindowStart.setMonth(currentWindow.end.getMonth() - 6);
    } else if(view === Views.YEAR) {
      timeline.current!.setOptions({
        ...BASE_OPTIONS_YEAR
      });

      setShowMajorLabels(true);
      newWindowStart.setFullYear(currentWindow.end.getFullYear() - 3);
    }

    timeline.current!.setWindow(newWindowStart, newWindowEnd, {animation: true});
  };

  useEffect(() => {
    if(dates && timeline.current) {
      changeView();
    }
  }, [view, dates, isLoading, props.horizontalInView]);

  useEffect(() => {
    const today = new Date();

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

    const threeMonthsInFuture = new Date();
    threeMonthsInFuture.setMonth(threeMonthsInFuture.getMonth() + 3);

    const weekInFuture = new Date();
    weekInFuture.setDate(weekInFuture.getDate() + 7);

    setDates({
      today,
      threeMonthsAgo,
      sixMonthsAgo,
      oneYearAgo,
      twoYearsAgo,
      threeMonthsInFuture,
      weekInFuture
    });
  }, []);

  useEffect(() => {
    let data: TimelineEvent[] = [];

    props.encounters.forEach((encounter) => {
      if(encounter.type === EncounterType.ADT) {
        data.push(mapAdtItem(encounter.content as ADTItem));
      } else if (encounter.type === EncounterType.OPENBEDS_CRISIS) {
        data.push(mapCrisisEvent(encounter.content as IntakeDetails));
      } else if (encounter.type === EncounterType.OPENBEDS_REFERRAL) {
        data.push(mapReferralEvent(encounter.content as MasterReferralResponse));
      } else if (encounter.type === EncounterType.JUSTICE_INVOLVED){
        data.push(mapJusticeInvolvedEvent(encounter.content as JusticeInvolvedItem));
      } else if (encounter.type === EncounterType.FINDHELP_SDOH) {
        data.push(mapSdohItem(encounter.content as SDOHItem));
      }
    });

    // vis js timeline update does not remove events only adds new ones or updates existing
    // so need to clear and then update to prevent removed events from hanging around
    timelineData.current.clear();
    timelineData.current.update(data);
  }, [props.encounters]);

  useEffect(() => {
    if(!isLoading && Helpers.isEmptyArray(props.encounters)) {
      props.setHorizontalInView(true);
    }
  }, [props.encounters, isLoading]);

  useEffect(() => {
    if(dates) {
      initTimeline();

      return () => {
        timeline!.current!.off('rangechanged');
        timeline!.current!.off('doubleClick');
        document.getElementsByClassName('vis-timeline')[0]?.remove();
      };
    }
  }, [dates]);

  const handleButtonBarClick = (item: { name: string, selected: boolean}) => {
    if(item.name === 'Go To Today') {
      jumpToDate(new Date());
    } else if(item.name === Views.WEEK.displayName) {
      setView(Views.WEEK);
    } else if(item.name === Views.MONTH.displayName) {
      setView(Views.MONTH);
    } else if(item.name === Views.YEAR.displayName) {
      setView(Views.YEAR);
    } else if(item.name === 'Show Gridlines' || item.name === 'Hide Gridlines') {
      setShowGridlines(!showGridlines);
    }
  };

  return (
    <VisTimelineContainer>
      <div className={css({display: 'flex', justifyContent: 'space-between'})}>
        <InfoIconTooltip html={infoTooltipContent}
          size={'2x'} color={colors.gray3}
          style={{marginLeft: '1rem'}}/>

        <ButtonBar
          onClick={(item) => handleButtonBarClick(item)}
          className={css({marginTop: 0})}
          buttonItems={[
            {
              name: 'Go To Today',
              selected: false,
            },
            {
              name: 'Week',
              selected: view.displayName === 'Week'
            },
            {
              name: 'Month',
              selected: view.displayName === 'Month'
            },
            {
              name: 'Year',
              selected: view.displayName === 'Year'
            },
            {
              name: showGridlines ? 'Hide Gridlines' : 'Show Gridlines',
              selected: false
            }
          ]}
        />
      </div>

      { (isLoading && props.horizontalInView) && <Loading/>}

      <div id="visualization"
        ref={timelineRef}
        className={`${showGridlines ? '' : 'hide-grid'} 
        ${showMajorLabels ? 'show-major-labels' : 'hide-major-labels'}`}>
        {
          hiddenArrow !== 'left' &&
           <VisNavLeft
             showMajorLabels={showMajorLabels}
             onClick={() => {move(BASE_OPTIONS_WEEK.rtl ? -0.2 : 0.2);}}>
             <Icon iconClass={'arrow-left'} className={css({fontSize: '20px'})} color={colors.white}/>
           </VisNavLeft>
        }

        {
          hiddenArrow !== 'right' &&
          <VisNavRight
            showMajorLabels={showMajorLabels}
            onClick={() => {move(BASE_OPTIONS_WEEK.rtl ? 0.2 : -0.2);}}>
            <Icon iconClass={'arrow-right'} className={css({fontSize: '20px'})} color={colors.white} />
          </VisNavRight>
        }

        {
          !showMajorLabels && <VisibleYearLabel>{visibleYear}</VisibleYearLabel>
        }
      </div>
    </VisTimelineContainer>
  );
};

export default VisTimeline;

