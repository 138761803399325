import React, {useContext, useEffect} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {SmallTypography} from 'patient-ping-remedy/packages/typography/lib/';
import FileInput from 'patient-ping-remedy/packages/file-input';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';
import {ToastType} from 'patient-ping-remedy/packages/toast';
import {CarecoApiContext} from '../../../app-context/careco-api-context';
import {useAlertStore} from '../../../store/alert_store';
import {createFormFieldConfig, useForm} from '../../../hooks/useForm';
import InfoIconTooltip from '../../common/InfoIconTooltip';
import {DownloadWrapper, Wrapper} from './BulkUserUploadTool.styles';
import Validate from '../../../helpers/validate';
import Helpers from '../../../helpers/helpers';

export default function BulkUserUploadTool() {
  const { carecoApi } = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async (file: File) =>
      carecoApi?.postBulkUserUploadTool(file),
    onSuccess() {
      addAlert({
        content: 'File uploaded successfully. Bulk user upload will now start in the background.',
        type: ToastType.SUCCESS,
      });
      queryClient.invalidateQueries({ queryKey: ['users'] })
        .then(r => console.log(r));
    },
    onError() {
      addAlert({
        content: 'Failed to upload file. Bulk user upload will not be started.',
        type: ToastType.ERROR,
      });
    },
  });

  const { form, isValid, setInitialForm, handleChange, handleSubmit } = useForm(() => {
    mutate(form.file.value[0]);
  });

  useEffect(() => {
    setInitialForm({
      ...createFormFieldConfig(
        'file',
        [],
        { valid: false, message: '' },
        [Validate.required, Validate.validateFile],
        true,
      ),
    });
  }, []);

  const handleDownload = () => {
    Helpers.downloadFile('/templates/bulk-user-upload-template.csv', 'bulk-user-upload-template.csv');
  };

  return (
    <Wrapper>
      <FileInput
        acceptedFiles=".csv"
        label="Select a file"
        onChange={(files: File[]) => handleChange(null, 'file', files)}
        value={form.file?.value ?? []}
        buttonText="Browse"
        buttonType={StyleType.SECONDARY}
        error={form.file?.valid.message}
      />
      <DownloadWrapper>
        <span>
          <SmallTypography>Download CSV Template </SmallTypography>
          <InfoIconTooltip text="This is an example file with the format which is expected by the Bulk User Upload tool." />
        </span>
        <Button styleType={StyleType.TERTIARY} onClick={handleDownload}>Download Template</Button>
      </DownloadWrapper>
      <div>
        <Button disabled={isPending || !isValid()} onClick={handleSubmit}>Upload</Button>
      </div>
    </Wrapper>
  );
}
