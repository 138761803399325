import {NotificationInfo} from './notification';
import {EncounterType} from "./client-profile";

export enum MixpanelEventType {
    BIH_USER_LOGGED_IN = "BIH - User Logged in",
    BIH_ADMIN_PAGE_VIEWED = "BIH - Admin Page Viewed",
    BIH_SSO_LAUNCHED = "BIH - SSO Launched",
    BIH_NOTIFICATIONS_UPDATED = "BIH - Notifications Updated",
    BIH_NOTIFICATIONS_FOR_ALL_CLIENTS = "BIH - Notifications for All Clients",
    BIH_USER_INVITED = "BIH - User Invited",
    BIH_FORM_UPLOADED = "BIH - Form Upload Completed",
    BIH_FORM_EXPORTED = "BIH - Form Exported",
    BIH_FORM_DELETED = "BIH - Form Deleted",
    BIH_CLIENT_LIST_VIEWED = "BIH - Client List Viewed",
    BIH_VIEW_FILTERED= "BIH - View Filtered",
    BIH_TABLE_SEARCHED = "BIH - Table Searched",
    BIH_TABLE_SORTED = "BIH - Table Sorted",
    BIH_FORM_UPLOAD_MODAL_OPENED = "BIH - Form Upload Modal Opened",
    BIH_CLIENT_PROFILE_VIEWED = "BIH - Client Profile Viewed",
}

export enum ViaType {
    EMAIL = "Email",
    NOTIFICATIONS = "Notifications",
    CLIENT_PROFILE = "Client Profile",
    CLIENT_LIST = "Client List",
    ADMIN = "Admin",
    USER_PROFILE = "User Profile",
}

export enum AdminPageTab {
    USERS = "Users",
    GROUPS = "Groups",
    ORGANIZATIONS = "Organizations",
}

export enum ClientPageTab {
    SUMMARY = "Summary",
    FORMS = "Forms",
    PDMP = "PDMP",
}

export enum SSOLaunchMethod {
    CREATE_BUTTON = "Create Button",
    APPLICATION_MENU = "Application Menu",
}

export enum SSOApplication {
    OPENBEDS_REFERRALS = "OpenBeds Referrals",
    OPENBEDS_CRISIS = "OpenBeds Crisis",
    FINDHELP = "FindHelp",
    ROUND_TRIP = "RoundTrip",
}

export enum TableName{
    CLIENT_LIST = "Clients",
    USERS = "Users",
    GROUPS = "Groups",
    ORGANIZATIONS = "Organizations",
    FORMS = "Forms",
    CLIENT_PROFILE = "Client Timeline"
}

export enum FormStatus {
    COMPLETED = "Completed",
}

export enum FormOrigin {
    UPLOADED = "Uploaded",
}

export enum FormExportMethod {
    PRINT = "Print",
    DOWNLOAD = "Download",
}

export enum TabName{
    CLIENT_LIST = "Client List",
    USERS = "Users",
    GROUPS = "Groups",
    ORGANIZATIONS = "Organizations",
    FORMS = "Forms",
    CLIENT_PROFILE = "Client Timeline"
}

export interface MixpanelEvent{
    eventName: MixpanelEventType;
    viewPortWidth: number;
    viewPortHeight: number;
    $os: string;
    device: string;
    currentApplicationLocation: string;
    pageUrl: string;
    via: Nullable<ViaType>;
}

export interface MixpanelAdminPageEvent extends MixpanelEvent {
    tabViewed: AdminPageTab;
    adminAccess: string
}

export interface MixpanelSSOLaunchEvent extends MixpanelEvent {
    applicationName: SSOApplication,
    launchMethod: SSOLaunchMethod;
}

export interface MixpanelNotificationsUpdatedEvent extends MixpanelEvent {
    notificationType: NotificationInfo['profileLabelText'];
    notificationEnabled: boolean;
    eventsEnabled: string[];
    notificationSchedule: string;
    deliveryMethod: string[];
}

export interface MixpanelNotificationsForAllClientsEvent extends MixpanelEvent {
    notificationsForAllClients: boolean;
}

export interface MixpanelSortEvent extends MixpanelEvent{
    tableName: TableName,
    sortCriteria: string
}

export interface MixpanelUserInvitedEvent extends MixpanelEvent {
    sender: string,
    invitee: string,
    inviteeGroupIds: number[]
}

export interface MixpanelCurrentGroupDetails {
    currentGroupName: string,
    currentStateName: string,
    currentGroupId: number,
    currentGroupType: string,
    currentOrganizationName: string,
    currentOrganizationId: string,
}

export interface MixpanelFormEvent extends MixpanelEvent, MixpanelCurrentGroupDetails {
    clientId: string,
    formId: number,
    formName: string,
    formType: string,
    fileSize?: string,
    exportMethod?: FormExportMethod,

    // Currently, has only one property, but can be extended in the future.
    formStatus?: FormStatus,
    formOrigin?: FormOrigin,
    formUploadTime?: number,
}

export interface MixpanelClientListEvent extends MixpanelEvent, MixpanelCurrentGroupDetails {}

export interface MixpanelViewFilteredEvent extends MixpanelEvent{
    viewName: string;
    filterCriteria: string[];
}

export interface MixpanelTableSearchedEvent extends MixpanelEvent{
    tableName: string;
}

export interface MixpanelClientProfilePageViewedEvent extends MixpanelEvent, MixpanelCurrentGroupDetails {
    clientId: string;
    tabViewed: ClientPageTab;
    encounterIds: {
        [key in EncounterType]?: string[];
    };
}

export interface MixpanelFormUpload extends MixpanelEvent, MixpanelCurrentGroupDetails {
    clientId: string
}
