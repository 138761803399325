import Header from "../../../../common/pages/Header";
import {
  Container,
  HeaderWrapper,
  LeftColumn,
  LeftColumnContent,
  RightColumn,
  RightColumnContent
} from "./ClientSummaryTab.styles";
import {
  ADTItem,
  ClientEncounter,
  ClientProfile,
  EncounterType,
  EncounterTypes
} from "../../../../../api/dto/client-profile";
import ClientSummaryTimeline from "./ClientSummaryTimeline";
import VisTimeline from "../../../../common/horizontal_timeline/VisTimeline";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useAuthStore} from "../../../../../store/auth_store";
import PermissionsHelpers from "../../../../../helpers/permissions_helpers";
import Helpers from "../../../../../helpers/helpers";
import {useRosterStore} from "../../../../../store/roster_store";
import PdmpScoreTile, {PdmpProps} from "../pmp_tab/PdmpScoreTile";
import {GroupTypes} from "./timeline_components/ADTCard.constants";
import {InView} from "react-intersection-observer";
import FilterComponent, {DefaultFilterOption} from "../../../../common/FilterComponent";
import {
  ClientPageTab,
  MixpanelClientProfilePageViewedEvent,
  MixpanelEventType,
  MixpanelViewFilteredEvent,
  TabName
} from "../../../../../api/dto/mixpanel";
import {getCurrentGroupDetails, getMixpanelEventProperties} from "../../../../../helpers/mixpanel_helpers";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";

type Props = {
  patient: ClientProfile;
  encounters: ClientEncounter[];
  pdmp?: PdmpProps;
  setInView: (value: boolean) => void;
  isLoading: boolean;
  isPolling: boolean;
  horizontalInView: boolean;
  setHorizontalInView: (value: boolean) => void;
  forceRefresh: Function;
  openDemographics: Function;
}

function cleanedAdtEvents(clientEncounters: ClientEncounter[]): ClientEncounter[] {
  return clientEncounters.filter((encounter) => {
    if (encounter.type === EncounterType.ADT) {
      const adtContent = encounter.content as ADTItem;
      const facilityType = adtContent.admittingFacility?.type ?? 'Unknown';
      return GroupTypes[facilityType] !== undefined;
    }
    return true;
  });
}

const filterOptions: DefaultFilterOption[] = [
  {
    type: 'multi-select-checkbox',
    label: 'EVENTS',
    dataType: 'array',
    options: Object.entries(EncounterTypes).map(([key, value]) => ({
      id: key,
      label: value,
      value: key
    })).sort((a, b) => a.label.localeCompare(b.label)),
    key: 'encounterTypes'
  }
];

const ClientSummaryTab = (props: Props) => {
  const { currentUser } = useAuthStore();
  const { currentRoster } = useRosterStore();
  const {carecoApi} = useContext(CarecoApiContext);
  const hasPdmp = useMemo(() => {
    if(!currentUser || !currentRoster) return false;

    return PermissionsHelpers.canForUserDataRoleRoster(
      currentUser,
      currentRoster,
      Helpers.getAppDataRoleDetails().levels.READ,
      Helpers.getAppDataRoleDetails().permissions.PDMP,
    );
  }, [currentUser, currentRoster]);

  const [currentFilters, setCurrentFilters] = useState<{encounterTypes: string[]}>({
    encounterTypes: []
  });

  const postMixpanelEvent = (updatedFilters: string[]) => {
    const updatedFilterValues = updatedFilters.map(filter => EncounterTypes[filter]);
    const event: MixpanelViewFilteredEvent = {
      ...getMixpanelEventProperties(MixpanelEventType.BIH_VIEW_FILTERED),
      viewName: TabName.CLIENT_PROFILE,
      filterCriteria: updatedFilterValues
    };
    carecoApi?.postMixpanelEvent(event);
  };

  const handleFilterChange = ( key: string, checked: boolean) => {
    let updatedFilters = [...currentFilters.encounterTypes];
    if(checked){
      updatedFilters.push(key);
    }else{
      updatedFilters = updatedFilters.filter(filter => filter !== key);
    }
    postMixpanelEvent(updatedFilters);
    setCurrentFilters({encounterTypes: updatedFilters});
  };

  const cleanedClientEncounters = useMemo(() => {
    return cleanedAdtEvents(props.encounters);
  }, [props.encounters]);

  const filteredEncounters = useMemo(() => {
    const selectedTypes = currentFilters.encounterTypes;
    if (selectedTypes.length === 0) {
      return cleanedClientEncounters;
    } else {
      return cleanedClientEncounters.filter(
        (encounter) => {
          if (encounter.type === EncounterType.ADT) {
            const adtContent = encounter.content as ADTItem;
            const displayName = GroupTypes[adtContent.admittingFacility?.type ?? 'Unknown'].displayName;
            if(displayName === 'Hospital') {
              return selectedTypes.includes('HOSPITAL');
            }else if(displayName === 'Post Acute') {
              return selectedTypes.includes('PAC');
            }
            return false;
          }
          return selectedTypes.includes(encounter.type);
        }
      );
    }
  }, [ currentFilters, cleanedClientEncounters]);

  useEffect(() => {
    if (currentRoster && props.patient?.patient?.patientId && props.encounters) {
      const mixpanelEvent: MixpanelClientProfilePageViewedEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_CLIENT_PROFILE_VIEWED),
        ...getCurrentGroupDetails(currentRoster),
        clientId: props.patient?.patient?.patientId,
        tabViewed: ClientPageTab.SUMMARY,
        encounterIds: {}
      };
      props.encounters.forEach(encounter => {
        switch (encounter.type) {
        case EncounterType.ADT:
          if (!mixpanelEvent.encounterIds.ADT) {
            mixpanelEvent.encounterIds.ADT = [];
          }
          mixpanelEvent.encounterIds[EncounterType.ADT].push(encounter.content.encounterId);
          break;

        case EncounterType.OPENBEDS_REFERRAL:
          if (!mixpanelEvent.encounterIds.OPENBEDS_REFERRAL) {
            mixpanelEvent.encounterIds.OPENBEDS_REFERRAL = [];
          }
          mixpanelEvent.encounterIds[EncounterType.OPENBEDS_REFERRAL].push(encounter.content.master_referral_id);
          break;

        case EncounterType.OPENBEDS_CRISIS:
          if (!mixpanelEvent.encounterIds.OPENBEDS_CRISIS) {
            mixpanelEvent.encounterIds.OPENBEDS_CRISIS = [];
          }
          mixpanelEvent.encounterIds[EncounterType.OPENBEDS_CRISIS].push(encounter.content.uuid);
          break;

        case EncounterType.FINDHELP_SDOH:
          if (!mixpanelEvent.encounterIds.FINDHELP_SDOH) {
            mixpanelEvent.encounterIds.FINDHELP_SDOH = [];
          }
          mixpanelEvent.encounterIds[EncounterType.FINDHELP_SDOH].push(encounter.content.referralId);
          break;

        case EncounterType.JUSTICE_INVOLVED:
          if (!mixpanelEvent.encounterIds.JUSTICE_INVOLVED) {
            mixpanelEvent.encounterIds.JUSTICE_INVOLVED = [];
          }
          mixpanelEvent.encounterIds[EncounterType.JUSTICE_INVOLVED].push(encounter.content.id);
          break;
        }
      });
      carecoApi?.postMixpanelEvent(mixpanelEvent);
    }
  }, [currentRoster, props.patient?.patient?.patientId]);

  if (!props.encounters) return null;

  return (
    <>
      <Container singleColumn={hasPdmp ?? false}>
        <LeftColumn>
          <Header margin="3rem">Timeline</Header>
          <LeftColumnContent>
            <VisTimeline encounters={cleanedClientEncounters}
              horizontalInView={props.horizontalInView}
              setHorizontalInView={props.setHorizontalInView}
              isLoading={props.isLoading}
              isPolling={props.isPolling}
            />
            <HeaderWrapper>
              <Header margin="0">Recent Events</Header>
              <FilterComponent
                filterFunction={handleFilterChange}
                filterArgs={{
                  filterOptions: filterOptions,
                  currentFilters,
                }}
              />
            </HeaderWrapper>
            <ClientSummaryTimeline
              patient={props.patient}
              encounters={filteredEncounters}
              isLoading={props.isLoading}
              isPolling={props.isPolling}
              forceRefresh={props.forceRefresh}
              openDemographics={props.openDemographics}
            />
            <InView onChange={(inView) => props.setInView(inView)} children={<span/>}/>
          </LeftColumnContent>
        </LeftColumn>
        <RightColumn>
          { hasPdmp && props.pdmp &&
            <>
              <Header margin="3rem">Controlled substance clarity score</Header>
              <RightColumnContent>
                <PdmpScoreTile  {...props.pdmp}/>
              </RightColumnContent>
            </>
          }
        </RightColumn>
      </Container>
    </>
  );
};

export default ClientSummaryTab;
