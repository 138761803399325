import React from "react";
import {colors} from "patient-ping-remedy/packages/theme";
import {
  MenuItem,
  MenuItemIcon,
  MenuItemSpacer,
  MenuItemTrailingIcon,
  MenuItemTypography
} from "patient-ping-remedy/packages/menu";
import PermissionsHelpers from "../../helpers/permissions_helpers";
import {useAuthStore} from "../../store/auth_store";
import {useOpenBeds} from "../../hooks/useOpenBeds";
import Helpers from "../../helpers/helpers";
import ToolTip from "patient-ping-remedy/packages/tool-tip";
import {ICONS} from "patient-ping-remedy/packages/icon";
import DisplayHelpers from "../../helpers/display_helpers";
import {SSOApplication, SSOLaunchMethod} from "../../api/dto/mixpanel";
import {AppMenu, AppMenuSubMenu, AppMenuSubMenuTitle} from "patient-ping-remedy/packages/application-menu";

const ApplicationMenu = () => {
  const { currentUser } = useAuthStore();
  const { openOpenBeds } = useOpenBeds();

  const crisisEnabled = currentUser ? PermissionsHelpers.canForUserDataRole(
    currentUser,
    Helpers.getAppDataRoleDetails().levels.SSO,
    Helpers.getAppDataRoleDetails().permissions.OB_CRISIS
  ) : false;

  const referralsEnabled = currentUser ? PermissionsHelpers.canForUserDataRole(
    currentUser,
    Helpers.getAppDataRoleDetails().levels.SSO,
    Helpers.getAppDataRoleDetails().permissions.OB_REFERRALS
  ) : false;

  return (
    <AppMenu width={'32rem'} popoverMarginTop popoverMarginBottom>
      <AppMenuSubMenu>
        <AppMenuSubMenuTitle>YOUR APPS</AppMenuSubMenuTitle>
        <MenuItem
          onClick={() => openOpenBeds(SSOApplication.OPENBEDS_REFERRALS, SSOLaunchMethod.APPLICATION_MENU)}
          disabled={!referralsEnabled}
        >
          <MenuItemIcon iconClass={'procedures'} color={colors.primary2} />
          <MenuItemTypography>DTRN eReferral</MenuItemTypography>
          <MenuItemSpacer />
          {referralsEnabled ? (
            <MenuItemTrailingIcon iconClass={ICONS["external-link"]} />
          ) : (
            <ToolTip
              text={DisplayHelpers.contactAdminMessage()}
              position="bottom-start"
              testId="referrals-disabled-tooltip"
            >
              <MenuItemTrailingIcon iconClass={ICONS["info-circle"]} />
            </ToolTip>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => openOpenBeds(SSOApplication.OPENBEDS_CRISIS, SSOLaunchMethod.APPLICATION_MENU)}
          disabled={!crisisEnabled}
        >
          <MenuItemIcon iconClass={'ambulance'} color={colors.primary2} />
          <MenuItemTypography>Crisis</MenuItemTypography>
          <MenuItemSpacer />
          {crisisEnabled ? (
            <MenuItemTrailingIcon iconClass={ICONS["external-link"]} />
          ) : (
            <ToolTip
              text={DisplayHelpers.contactAdminMessage()}
              position="bottom-start"
              testId="crisis-disabled-tooltip"
            >
              <MenuItemTrailingIcon iconClass={ICONS["info-circle"]} />
            </ToolTip>
          )}
        </MenuItem>
      </AppMenuSubMenu>
    </AppMenu>
  );
};

export default ApplicationMenu;
