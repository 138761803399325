import {colors} from "patient-ping-remedy/packages/theme";
import {StandardSemiBoldTypography} from "patient-ping-remedy/packages/typography";
import styled from '@emotion/styled';

export const Container = styled.div({
  marginTop: '20px',
  display: 'inline',
  marginLeft: '40px',
});

export const ExpandMoreStyled = styled.button({
  background: 'none',
  border: 'none',
  fontSize: '16px',
  marginTop: '10px',
  textAlign: 'left',
  width: 'max-content',
  padding: 0,
  color: colors.tertiary2,
  cursor: 'pointer',
});

export const Required = styled(StandardSemiBoldTypography)`
  color: ${colors.red5};
`;

export const ExpandMoreContentStyled = styled.div`
    margin-top: 10px;
    margin-left: 40px;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
`;


