import React, {useContext, useEffect, useRef, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {CarecoApiContext} from "../../../app-context/careco-api-context";
import DropdownList from "patient-ping-remedy/packages/dropdown-list";
import {css} from "@emotion/css";
import {Roster} from "../../../api/dto/dto";
import RosterHelpers from "../../../helpers/roster_helpers";
import {ScanRosterHeader, SpinnerHint, StyledSpinner} from "./ScanRosterTool.styles";
import {LabelTypography} from "patient-ping-remedy/packages/typography/lib/";
import Button from "patient-ping-remedy/packages/button";
import InfoIconTooltip from "../../common/InfoIconTooltip";
import {useAlertStore} from "../../../store/alert_store";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {JobStatus} from "../../../api/dto/client-profile";
import CarecoApi from "../../../api/careco-api";

const ScanRosterTool = () => {
  const {carecoApi} = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();
  const [isScanning, setIsScanning] = useState(false);

  const killPolling = useRef(false);

  const { data: rosters } = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const [roster, setRoster] = useState<Roster|null>();

  const dropDownItems = rosters?.rosterOptions ?? [];

  const startScan = async () => {
    if (!roster || !carecoApi) {
      return;
    }

    setIsScanning(true);

    await carecoApi?.postScanRosterTool(roster.id);
    await pollApi(carecoApi);
  };

  useEffect(() => {
    if (!carecoApi || !rosters) {
      return;
    }
    pollApi(carecoApi);

    return () => {
      killPolling.current = true;
    };
  }, [carecoApi, rosters]);

  const pollApi = async (carecoApi: CarecoApi) => {
    killPolling.current = false;
    let response = await carecoApi.getScanRosterTool();

    while (response.status === JobStatus.InProgress && !killPolling.current) {
      setIsScanning(true);
      await new Promise(r => setTimeout(r, process.env.REACT_APP_CLIENT_PROFILE_POLL_DELAY));

      response = await carecoApi.getScanRosterTool();
    }

    setIsScanning(false);

    if (response.status === null) {
      return;
    }

    if (response.status === JobStatus.Complete) {
      let scannedRoster = RosterHelpers.getRosterById(rosters, response.result?.rosterId);

      addAlert({
        content: `Finished scanning ${response.result?.count} clients in ${scannedRoster?.name}.`,
        type: ToastType.SUCCESS
      });
    } else if (response.status === JobStatus.Failed) {
      addAlert({
        content: `Roster scan failed.`,
        type: ToastType.ERROR
      });
    }
  };

  return (
    <>
      <ScanRosterHeader>
        <LabelTypography>Scan roster </LabelTypography>
        <InfoIconTooltip
          text={'Scans the selected roster to sync the Clients list with the corresponding Ping group. ' +
            'This should not need to be done on a regular basis, but may be useful when onboarding new groups. ' +
            'Depending on the number of clients on the roster, this will be slow.'}
        />
      </ScanRosterHeader>
      <DropdownList
        className={css({ height: '40px', zIndex: '2' })}
        items={dropDownItems}
        value={{
          label: roster?.name ? roster.name : 'Select a group',
          value: roster?.id ? roster.id.toString() : ''
        }}
        handleChange={(val) => setRoster(RosterHelpers.getRosterById(rosters, val?.value))}
        disabled={isScanning}
        id={'scan-roster-picker'}
      />
      <Button
        className={css({ marginTop: '30px' })}
        disabled={isScanning}
        onClick={startScan}
      >
        Scan
      </Button>
      {isScanning &&
          <>
            <StyledSpinner iconSize={'2x'} />
            <SpinnerHint>
            Scanning - this may take a long time. The process will continue if you leave this page.
            </SpinnerHint>
          </>
      }
    </>
  );
};

export default ScanRosterTool;
