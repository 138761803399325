import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {css} from "@emotion/css";
import ConfirmModal from "../../../../common/ConfirmModal";
import React, {useContext, useEffect, useRef, useState} from "react";
import {createFormFieldConfig, useForm} from "../../../../../hooks/useForm";
import Validate from "../../../../../helpers/validate";
import ValidatedInput from "../../../../common/ValidatedInput";
import RemedyDatePicker from "patient-ping-remedy/packages/date-picker";
import {
  ScoreDiv,
  Form,
  FormUploadModalStyle,
  ButtonWrapper,
  StyledSpacer,
  StyledHintTypography
} from "./FormUploadModal.styles";
import FileInput from "patient-ping-remedy/packages/file-input";
import DropdownList from "patient-ping-remedy/packages/dropdown-list";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";
import {useAlertStore} from "../../../../../store/alert_store";
import {FormUploadRequest} from "../../../../../api/dto/client-profile";
import {useRosterStore} from "../../../../../store/roster_store";
import Helpers from "../../../../../helpers/helpers";
import {GENERICERROR} from "../../manual_encounter/constants/BannerMessages";
import {AxiosError} from "axios";
import {
  calculateFileSize, getCurrentGroupDetails, getMixpanelEventProperties,
} from "../../../../../helpers/mixpanel_helpers";
import {MixpanelEventType, MixpanelFormEvent} from "../../../../../api/dto/mixpanel";
import FormExpandMoreButton from "./FormExpandMoreButton";
import {Required} from "./FormExpandMoreButton.styles";
import Checkbox from "patient-ping-remedy/packages/checkbox";
import Spacer from "patient-ping-remedy/packages/spacer";

type Props = {
  showFormUploadModal: boolean;
  setShowFormUploadModal: (show: boolean) => void;
  patientId: string;
};

const FORM_TYPES : Value[] = [
  {label: 'Discharge Instructions', value: 'DISCHARGE'},
];

const FormUploadModal = (props: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { isValid, isFormChanged, submitted, form, setInitialForm, handleChange } = useForm(handleFormFileCreate);
  const { carecoApi } = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();
  const { currentRoster } = useRosterStore();
  const queryClient = useQueryClient();

  const uploadStartTime = useRef(0);

  const createFormMutation = useMutation({
    mutationFn: async (form: FormData) => carecoApi?.postFormUpload(form),
    onSuccess: (formResponse) => {
      const uploadTime = new Date().getTime() - uploadStartTime.current;
      addAlert({content: 'Form submitted successfully.', type: ToastType.SUCCESS});
      queryClient.invalidateQueries({queryKey: ['forms', currentRoster?.id, props.patientId]})
        .then(r => console.log(r));
      props.setShowFormUploadModal(false);

      const mixpanelEvent : MixpanelFormEvent  = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_FORM_UPLOADED),
        ...getCurrentGroupDetails(currentRoster!),
        clientId: props.patientId,
        formId: formResponse?.id!,
        formName: form.name.value,
        formType: form.type.value.value,
        fileSize: calculateFileSize(form.file.value)!,
        formUploadTime: uploadTime,
      };
      carecoApi?.postMixpanelEvent(mixpanelEvent);
    },
    onError: (error:  AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to create form. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  async function handleFormFileCreate() {
    uploadStartTime.current = new Date().getTime();
    const formData = new FormData();
    formData.append('file', form.file.value[0]);

    const createFormRequest : FormUploadRequest = {
      name: form.name.value.trim(),
      form_type: form.type.value.value,
      form: {
        roster_id: currentRoster!.id,
        completed_at: form.date_completed.value.toISOString(),
        score: Helpers.isEmpty(form.score.value) ? null : form.score.value.trim(),
        client_id: props.patientId,
      },
    };
    formData.append('data', JSON.stringify(createFormRequest));

    createFormMutation.mutate(formData);
  }

  useEffect(() => {
    setInitialForm({
      ...createFormFieldConfig(
        'type',
        null,
        { valid: true, message: '' },
        [Validate.required],
        true
      ),
      ...createFormFieldConfig(
        'name',
        '',
        { valid: false, message: '' },
        [Validate.required, Validate.formName],
        true
      ),
      ...createFormFieldConfig(
        'score',
        '',
        { valid: true, message: '' },
        [Validate.formScore],
        false
      ),
      ...createFormFieldConfig(
        'date_completed',
        new Date(),
        { valid: true, message: '' },
        [Validate.required],
        true
      ),
      ...createFormFieldConfig(
        'file',
        [],
        { valid: false, message: '' },
        [Validate.required, Validate.validatePDFFile],
        true
      ),
      ...createFormFieldConfig(
        'attestation',
        false,
        { valid: false, message: '' },
        [Validate.boolean],
        true
      ),
    });
  }, [setInitialForm, props.showFormUploadModal]);

  const handleCancelClick = () => {
    if(isFormChanged() && !submitted) {
      setShowConfirmModal(true);
    } else {
      props.setShowFormUploadModal(false);
    }
  };

  const handleLeaveConfirmModal = () => {
    setShowConfirmModal(false);
    props.setShowFormUploadModal(false);
  };

  return (
    <>
      <FormUploadModalStyle
        id={"form-upload-modal"}
        headerText={"Upload form"}
        isOpen={props.showFormUploadModal}
      >
        <Form>
          <DropdownList
            id="upload-form-type"
            error={!form.type?.valid.valid}
            errorMessage={form.type?.valid.message}
            items={FORM_TYPES}
            label="Type"
            value={form.type?.value}
            handleChange={(value : Value | null) => handleChange(null, 'type', value) }
            required={true}
          />

          <ValidatedInput
            className={css({ width: '100%' })}
            labelText="Name"
            value={form.type?.name}
            onChange={(e) => handleChange(e, 'name', e.target.value)}
            validate={() => form.name.valid.valid ? '' : form.name.valid.message}
            required={'required'}
            id="upload-form-name-input"
          />

          <ScoreDiv>
            <ValidatedInput
              className={css({ width: '50%' })}
              labelText="Score"
              value={form.score?.value}
              onChange={(e) => handleChange(e, 'score', e.target.value)}
              validate={() => form.score.valid.valid ? '' : form.score.valid.message}
              id="upload-form-score-input"
            />

            <RemedyDatePicker
              externalErrorText="Invalid Date"
              id="upload-form-date-input"
              required={'required'}
              labelText="Date completed"
              maxDate={new Date()}
              // @ts-ignore
              onChange={(date) => handleChange(null, 'date_completed', date)}
              value={form.date_completed?.value}
            />
          </ScoreDiv>

          <FileInput
            acceptedFiles=".pdf"
            label="Select a file"
            onChange={(files: File[]) => handleChange(null, 'file', files)}
            value={form.file?.value ?? []}
            buttonText={'Browse'}
            buttonType={'secondary'}
            error={form.file?.valid.message}
          />

          <Checkbox
            className={css({ marginTop:'2rem' })}
            key={'attestation'}
            onChange={(checked) => handleChange(null, 'attestation', checked)}
            value={form.attestation?.value}
            // @ts-ignore
            label={
              <>
                <Required>* </Required>
                <Spacer itemWidth={'0.5rem'}>
                </Spacer>I am attesting that the uploaded form is a Discharge Instructions
              </>
            }
          />
        </Form>

        <FormExpandMoreButton/>

        <ButtonWrapper>
          <Button key={'cancelUploadButton'}
            onClick={handleCancelClick}
            styleType={StyleType.TERTIARY}>Cancel</Button>
          <Button key={'uploadButton'}
            onClick={handleFormFileCreate}
            className={css({ marginLeft: '20px' })}
            disabled={!isValid() || createFormMutation.status === "pending"}>Upload</Button>
        </ButtonWrapper>

        <StyledSpacer />

        <StyledHintTypography>
          By uploading documents here, I acknowledge and agree that I have performed
          back-ups of such documents and am not relying solely on Bamboo Health to store
          and maintain such documents.  Further, I acknowledge and agree that Bamboo
          Health is not responsible for any loss of data or documentation, including
          but not limited to, accidental deletion, hardware failure, software
          malfunction, or any other unforeseen circumstances.
        </StyledHintTypography>

        <StyledHintTypography>
          I agree that I am using this platform for ease of form completion and to
          aid in process transparency and general communication across care team members.
          This platform does not replace existing review, approval, or submission workflows.
        </StyledHintTypography>
      </FormUploadModalStyle>

      <ConfirmModal show={showConfirmModal}
        leaveFunction={handleLeaveConfirmModal}
        showFunction={setShowConfirmModal} />
    </>
  );
};

export default FormUploadModal;

