import Helpers from "./helpers";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import DisplayHelpers from "./display_helpers";

export interface ValidationResponse {
  valid: boolean;
  message: string;
}

type RosterValueUser = {
  roster: Value;
  dataRole: Value;
  featureRole: Value;
}

type RosterValueSuperAdmin = {
  roster: Value;
}

const Validate = {
  // truthy required param in createFormFieldConfig means undefined/null are not allowed
  required(val: string, name: string, required: boolean) : ValidationResponse {
    let valid = (required ? Helpers.isDefined(val) : true) && !Helpers.isEmpty(val);
    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }
  },
  boolean(val: boolean | null, name: string, required: boolean) : ValidationResponse {
    return { valid: !!val, message: '' };
  },
  firstName(val: string, name: string, required: boolean) : ValidationResponse {
    if(required && (Helpers.isEmpty(val) || !Helpers.isDefined(val))) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    const re = /^[a-zA-Z \-']{2,25}$/;
    let valid =  re.test(val);

    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {
        valid: false, message: 'First name must be at least 2-25 characters and include only alphabetical characters or - \''
      };
    }
  },
  lastName(val: string, name: string, required: boolean) : ValidationResponse {
    if(required && (Helpers.isEmpty(val) || !Helpers.isDefined(val))) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    const re = /^[a-zA-Z \-',.]{2,50}$/;
    let valid =  re.test(val);

    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {
        valid: false, message: 'Last name must be at least 2-50 characters and include only alphabetical characters or - \' . ,'
      };
    }
  },
  formName(val: string, name: string, required: boolean) : ValidationResponse {
    if(required && (Helpers.isEmpty(val) || !Helpers.isDefined(val))) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    const re = /^[A-Za-z0-9_'\-\s.()]{2,200}$/;
    let valid =  re.test(val);

    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: 'The file name must be between 2 and 200 characters long, ' +
          'containing only letters (A-Z, a-z), numbers (0-9), underscores (_), period (.), parentheses (()), ' +
          'and/or hyphens (-).'};
    }
  },
  formScore(val: string, name: string, required: boolean) : ValidationResponse {
    if(required && (Helpers.isEmpty(val) || !Helpers.isDefined(val))) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    const re = /^[A-Za-z0-9_ '\-\s.]{0,50}$/;
    let valid =  re.test(val);

    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: 'The score can be up to 50 characters long and can only ' +
          'include letters (A-Z, a-z), numbers (0-9), periods (.), spaces ( ), and/or hyphens (-).'};
    }
  },
  validateRostersUsers(val: RosterValueUser[], name: string, required: boolean) {
    if(!Helpers.isDefined(val) && required) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    if(val?.length === 0) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} requires at least one selection.`};
    }

    let valid =  val?.every((v: RosterValueUser) => {
      return !Helpers.isEmpty(v.roster?.value) && !Helpers.isEmpty(v.dataRole?.value) && !Helpers.isEmpty(v.featureRole?.value);
    });

    if(valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} requires a valid selection for each roster.`};
    }
  },
  validateRostersSuperAdmin(val: RosterValueSuperAdmin[], name: string, required: boolean) {
    if(!Helpers.isDefined(val) && required) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    if(val?.length === 0) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} requires at least one selection.`};
    }

    let valid =  val?.every((v: RosterValueSuperAdmin) => {
      return !Helpers.isEmpty(v.roster?.value);
    });

    if(valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} requires a valid selection for each roster.`};
    }
  },
  email(email: string, name: string, required: boolean) : ValidationResponse {
    if(required && Helpers.isEmpty(email)) {
      return { valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    const re = /\S+@\S+\.\S+/;
    let valid =  re.test(email);

    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: 'Please provide a valid email address.'};
    }
  },
  phone(phone: string, name: string, required: boolean) : ValidationResponse {
    if(required && Helpers.isEmpty(phone)) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    let valid= re.test(phone);

    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: 'Phone number must be in the format (XXX) XXX-XXXX.'};
    }
  },
  validateInteger(val: string, name: string, required: boolean) : ValidationResponse {
    if(!Helpers.isDefined(val) && required) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    let valid = !Number.isNaN(Number(val));

    if (valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} must be a number.`};
    }
  },
  validateFile(val: FileList | File[], name: string, required: boolean) : ValidationResponse {
    if(required && (!Helpers.isDefined(val) || (val.length === 0))) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    return {valid: true, message: ''};
  },
  validatePDFFile(val: FileList | File[], name: string, required: boolean) : ValidationResponse {
    const validationResponse = Validate.validateFile(val, name, required);
    if (!validationResponse.valid) {
      return validationResponse;
    }

    if(required && (!Helpers.isDefined(val) || (val.length === 0))) {
      return {valid: false, message: `${DisplayHelpers.humanize(name)} is required.`};
    }

    let files;

    if(val instanceof FileList) {
      files = Array.from(val);
    } else {
      files = val;
    }

    let valid = files.every((file: File) => {
      return file.type === 'application/pdf' && file.size < 50000000;
    });

    if(valid) {
      return {valid: true, message: ''};
    } else {
      return {valid: false, message: 'Please provide a PDF under 50MB in size'};
    }
  },
  validateSln(val: any, name: string, required: boolean) : ValidationResponse {
    // not required, do not need checks
    if(!Helpers.isEmpty(val.value)) {
      if(Helpers.isEmpty(val.type) || Helpers.isEmpty(val.jurisdiction) || !Helpers.isDefined(val.jurisdiction)) {
        return {valid: false, message: ''};
      }
    }

    if(!Helpers.isEmpty(val.type)) {
      if(Helpers.isEmpty(val.value) || Helpers.isEmpty(val.jurisdiction) || !Helpers.isDefined(val.jurisdiction)) {
        return {valid: false, message: ''};
      }
    }

    if(!Helpers.isEmpty(val.jurisdiction) && Helpers.isDefined(val.jurisdiction)) {
      if(Helpers.isEmpty(val.value) || Helpers.isEmpty(val.type)) {
        return {valid: false, message: ''};
      }
    }

    return {valid: true, message: ''};
  }
};

export default Validate;
