import styled from '@emotion/styled';
import {sizes, fontSize} from 'patient-ping-remedy/packages/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizes.medium};
  font-size: ${fontSize.standard};
`;

export const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
`;
