import React, {useCallback} from 'react';
import DropdownList from "patient-ping-remedy/packages/dropdown-list";
import {css} from "@emotion/css";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import Icon from "patient-ping-remedy/packages/icon";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {StandardSemiBoldTypography} from "patient-ping-remedy/packages/typography";
import {colors} from "patient-ping-remedy/packages/theme";
import {RemoveRosterDiv, RosterDiv, Spacer, TextAlignLeft} from "./RosterSelection.styles";
import Helpers from "../../../helpers/helpers";
import {ValidationResponse} from "../../../helpers/validate";
import PermissionsHelpers from "../../../helpers/permissions_helpers";
import {RosterResponse, User} from "../../../api/dto/dto";
import {useAuthStore} from "../../../store/auth_store";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import RosterHelpers from "../../../helpers/roster_helpers";

type formRosters = {
  required: boolean,
  valid: ValidationResponse,
  validateFun: Function[] | null,
  value: RosterSelectionState[]
}

export interface RosterSelectionState {
  roster: Value;
  dataRole: Value;
  featureRole: Value;
}

interface RosterSelectionProps {
  user?: User,
  rosters?: RosterResponse;
  formRosters: formRosters;
  availableRosterOptions: Value[];
  rosterOptions: Value[];
  dataRoleOptions: Value[];
  featureRoleOptions: Value[];
  handleChange: (e: React.ChangeEvent | null, name: string, value: any) => void;
  isWithinModal?: boolean;
}

const RosterSelection = (props: RosterSelectionProps) => {
  const { currentUser } = useAuthStore();
  const addRosterSet = useCallback(() => {
    if (props.availableRosterOptions.length > 0) {
      const newRosterSet: RosterSelectionState = {
        roster: props.availableRosterOptions[0],
        dataRole: props.dataRoleOptions[0],
        featureRole: props.featureRoleOptions[0]
      };

      props.handleChange(null, 'rosters', [...props.formRosters.value, newRosterSet]);
    }
  }, [props]);

  const removeRosterSet = useCallback((index: number) => {
    props.handleChange(null, 'rosters', props.formRosters.value.filter((_: RosterSelectionState, i: number) => i !== index));
  },[props]);

  const handleRosterChange = (index: number, selectedRoster: Value | null ) => {
    if (selectedRoster) {
      const updatedRosterSets = [...props.formRosters.value];
      updatedRosterSets[index].roster = selectedRoster;
      props.handleChange(null, 'rosters', updatedRosterSets);
    }
  };

  const handleDataRoleChange = (index: number, value: Value | null ) => {
    if (value) {
      const updatedRosterSets = [...props.formRosters.value];
      updatedRosterSets[index].dataRole = value;
      props.handleChange(null, 'rosters', updatedRosterSets);
    }
  };

  const handleFeatureRoleChange = (index: number, value: Value | null ) => {
    if (value) {
      const updatedRosterSets = [...props.formRosters.value];
      updatedRosterSets[index].featureRole = value;
      props.handleChange(null, 'rosters', updatedRosterSets);
    }
  };

  return (
    <>
      {
        props.formRosters?.value?.map((rosterSet: RosterSelectionState, index: number) => {
          const roster = RosterHelpers.getRosterById(props.rosters, rosterSet.roster.value);

          let canManageUserPermissions = PermissionsHelpers.canForUserFeatureRoleRoster(currentUser!,
            roster!,
            Helpers.getAppFeatureRoleDetails().levels.MANAGE,
            Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS) && currentUser?.id !== props.user?.id;

          return (
            <div key={`roster-sets-${rosterSet.roster.value}`}>
              <RemoveRosterDiv>
                {index !== 0 && canManageUserPermissions && !PermissionsHelpers.isSuperAdmin(currentUser!) &&
                  <Button id={`remove-roster-group-${index}`} onClick={() => removeRosterSet(index)}
                    styleType={StyleType.TERTIARY}>
                    <Icon iconClass={'times'} color={colors.purple4}/>
                  </Button>
                }
              </RemoveRosterDiv>
              <RosterDiv>
                <DropdownList
                  data-testid={`user-roster-dropdown-${rosterSet.roster.value}`}
                  className={css({width: '100%'})}
                  items={props.availableRosterOptions}
                  isSearchable={true}
                  disabled={PermissionsHelpers.isSuperAdmin(props.user!) || !canManageUserPermissions}
                  value={rosterSet.roster}
                  handleChange={(value) => handleRosterChange(index, value)}
                  error={Helpers.isEmpty(rosterSet.roster.value)}
                  errorMessage={'Please select a group'}
                  id={`user-roster-dropdown-${rosterSet.roster.value}`}
                  label={'Group'}
                  required={!PermissionsHelpers.isSuperAdmin(props.user!)}
                  isWithinModal={props.isWithinModal}
                />
              </RosterDiv>
              {
                ((!PermissionsHelpers.isSuperAdmin(props.user!)) || !props.user) &&
                <RosterDiv>
                  <DropdownList
                    data-testid={`user-data-role-dropdown-${rosterSet.dataRole?.value}`}
                    className={css({width: '50%', marginRight: '20px'})}
                    items={props.dataRoleOptions}
                    value={rosterSet.dataRole || {label: '', value: ''}}
                    error={!Helpers.isDefined(rosterSet.dataRole?.value)}
                    errorMessage={'Please select a data role'}
                    disabled={!canManageUserPermissions}
                    handleChange={(value) => handleDataRoleChange(index, value)}
                    id={`user-data-role-dropdown-${rosterSet.dataRole?.value}`}
                    label={'Data role'}
                    required={true}
                    isWithinModal={props.isWithinModal}
                  />
                  <DropdownList
                    data-testid={`user-feature-role-dropdown-${rosterSet.featureRole?.value}`}
                    className={css({width: '50%'})}
                    items={props.featureRoleOptions}
                    value={rosterSet.featureRole || {label: '', value: ''}}
                    error={!Helpers.isDefined(rosterSet.featureRole?.value)}
                    errorMessage={'Please select a feature role'}
                    disabled={!canManageUserPermissions}
                    handleChange={(value) => handleFeatureRoleChange(index, value)}
                    id={`user-feature-role-dropdown-${rosterSet.featureRole?.value}`}
                    label={'Feature Role'}
                    required={true}
                    isWithinModal={props.isWithinModal}
                  />
                </RosterDiv>
              }

              <Spacer></Spacer>
            </div>
          );
        }
        )
      }

      {
        props.availableRosterOptions.length > 0 && !PermissionsHelpers.isSuperAdmin(props.user!) &&
        <TextAlignLeft>
          <Button id={"roster-selection-add-group"} onClick={addRosterSet} styleType={StyleType.TERTIARY}>
            <StandardSemiBoldTypography>
              <Icon iconClass={'plus'} color={colors.purple4}/> Add another group
            </StandardSemiBoldTypography>
          </Button>
        </TextAlignLeft>
      }
      {
        // want actually false here, not undefined or null
        (props.formRosters?.valid?.valid === false) &&
        <Banner className={css({ marginTop: '10px' })} type={BannerTypes.WARNING}> { props.formRosters?.valid?.message }</Banner>
      }
    </>
  );
};

export default React.memo(RosterSelection);
