import Loading from 'components/common/Loading';
import DisplayHelpers from 'helpers/display_helpers';
import Banner, {BannerTypes} from 'patient-ping-remedy/packages/banner';
import React from 'react';
import NotificationSetting from "./NotificationSetting";
import {
  CRISIS_EVENT,
  FIND_HELP_EVENT,
  HOSPITAL_EVENT,
  JI_EVENT,
  NotificationEventType,
  NotificationInfoMap,
  NotificationSettings,
  POST_ACUTE_CARE_EVENT,
  REFERRAL_EVENT
} from "../../../api/dto/notification";
import {User} from '../../../api/dto/dto';
import {NotificationHeader, NotificationHeading} from "./NotificationSettingList.styles";
import NotificationSettingDrawer from "./notification_details/NotificationSettingDrawer";
import NotificationOnlyAssignedClientSwitch from './NotificationOnlyAssignedClientSwitch';

type Props = {
  currentFetchedUser: User;
  notificationSettings: NotificationSettings;
  isLoading: boolean;
  loadError: Error | null;
}

const notificationEventEnabled = (notificationSettings: NotificationSettings, notificationEvent: NotificationEventType) => {
  switch (notificationEvent) {
  case REFERRAL_EVENT:
    return notificationSettings.referralSetting.enabled;
  case CRISIS_EVENT:
    return notificationSettings.crisisSetting.enabled;
  case HOSPITAL_EVENT:
    return notificationSettings.hospitalSetting.enabled;
  case JI_EVENT:
    return notificationSettings.jiSetting.enabled;
  case POST_ACUTE_CARE_EVENT:
    return notificationSettings.pacSetting.enabled;
  case FIND_HELP_EVENT:
    return notificationSettings.findHelpSetting.enabled;
  }
};

const NotificationSettingList = (props: Props) => {
  const [selectedEventType, setSelectedEventType] = React.useState<NotificationEventType | null>(null);

  if (props.isLoading) {
    return <Loading />;
  }

  return (
    <>
      {
        props.loadError &&
        <Banner type={BannerTypes.WARNING}>{ DisplayHelpers.displayErrorMessage(props.loadError) }</Banner>
      }
      {
        !props.isLoading && !props.loadError &&
        <div>
          <NotificationHeader>
            <NotificationHeading>Notifications</NotificationHeading>
            <NotificationOnlyAssignedClientSwitch currentFetchedUser={props.currentFetchedUser} />
          </NotificationHeader>
          {
            Object.keys(NotificationInfoMap).map(notificationKey => (
              <NotificationSetting
                key={notificationKey}
                notificationKey={notificationKey as NotificationEventType}
                enabled={
                  notificationEventEnabled(props.notificationSettings, notificationKey as NotificationEventType)
                }
                selectedType={setSelectedEventType}
              />
            ))
          }

          {
            !! selectedEventType &&
            <NotificationSettingDrawer
              notificationEvent={selectedEventType}
              notificationSettings={props.notificationSettings}
              isOpen={!!selectedEventType}
              onClose={() => setSelectedEventType(null)}
            />
          }
        </div>
      }
    </>
  );
}; 

export default React.memo(NotificationSettingList);
