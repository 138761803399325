import React from 'react';
import Wrapper from '../../common/pages/Wrapper';
import Header from '../../common/pages/Header';
import TabsComponent from '../../common/TabsComponent';
import DeploymentFooter from '../../DeploymentFooter';
import ScanRosterTool from './ScanRosterTool';
import BulkUserUploadTool from './BulkUserUploadTool';

const ToolsPage = () => {
  const tabs = [
    {
      value: 0,
      label: 'Scan Roster',
      content: <ScanRosterTool />
    },
    {
      value: 1,
      label: 'Bulk User Upload',
      content: <BulkUserUploadTool />
    },
  ];

  return (
    <Wrapper>
      <Header>
        Tools
      </Header>
      <TabsComponent tabs={tabs} />
      <DeploymentFooter />
    </Wrapper>
  );
};

export default ToolsPage;
