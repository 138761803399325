import React, {useContext, useEffect, useState} from 'react';
import {css} from '@emotion/css';
import {Value} from 'patient-ping-remedy/packages/dropdown-list/lib/DropdownList';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';
import Modal from 'patient-ping-remedy/packages/modal';
import DropdownList from 'patient-ping-remedy/packages/dropdown-list';
import {CarecoApiContext} from '../../../../app-context/careco-api-context';
import {useAuthStore} from '../../../../store/auth_store';
import {useRosterStore} from '../../../../store/roster_store';
import {createFormFieldConfig, useForm} from '../../../../hooks/useForm';
import {useOpenBeds} from '../../../../hooks/useOpenBeds';
import {useFindHelp} from '../../../../hooks/useFindHelp';
import Validate from '../../../../helpers/validate';
import Helpers from '../../../../helpers/helpers';
import DisplayHelpers from '../../../../helpers/display_helpers';
import PermissionsHelpers from '../../../../helpers/permissions_helpers';
import {useRoundTrip} from "../../../../hooks/useRoundTrip";
import {
  SSOApplication,
  SSOLaunchMethod
} from "../../../../api/dto/mixpanel";

type Props = {
  hideModal(): void;
}

export default function CreateReferral(props: Props) {
  const { carecoApi } = useContext(CarecoApiContext);
  const { currentUser } = useAuthStore();
  const { currentRoster } = useRosterStore();
  const { openOpenBeds } = useOpenBeds();
  const { openRoundTrip } = useRoundTrip();
  const { openFindHelp } = useFindHelp();

  const [isOpenBedsSelected, setIsOpenBedsSelected] = useState(true);
  const [roundTripSelected, setRoundTripSelected] = useState(false);

  const { form, isValid, setInitialForm, handleChange, handleSubmit } = useForm(async () => {
    props.hideModal();
    if (isOpenBedsSelected) {
      const applicationName = form.type.value.value === Helpers.getAppDataRoleDetails().permissions.OB_REFERRALS ?
        SSOApplication.OPENBEDS_REFERRALS : SSOApplication.OPENBEDS_CRISIS;

      await openOpenBeds(applicationName, SSOLaunchMethod.CREATE_BUTTON);
    }else if(roundTripSelected){
      await openRoundTrip();
    }else {
      await openFindHelp(currentRoster!.id, form.client?.value?.value);
    }
  });

  useEffect(() => {
    setInitialForm({
      ...createFormFieldConfig(
        'type',
        null,
        { valid: false, message: '' },
        [Validate.required],
        true,
      ),
      ...createFormFieldConfig(
        'client',
        null,
        { valid: false, message: '' },
        [Validate.required],
        true,
      ),
    });
  }, [setInitialForm]);

  const handleTypeChange = (value: Value | null) => {
    const isOpenBedsSelected = !!value && [
      Helpers.getAppDataRoleDetails().permissions.OB_REFERRALS,
      Helpers.getAppDataRoleDetails().permissions.OB_CRISIS,
    ].includes(value.value);

    const isRoundTripSelected = !!value && [
      Helpers.getAppDataRoleDetails().permissions.ROUNDTRIP,
    ].includes(value.value);
    setRoundTripSelected(isRoundTripSelected);
    setIsOpenBedsSelected(isOpenBedsSelected);
    form.client.value = null;
    form.client.valid = { valid: isOpenBedsSelected || isRoundTripSelected, message: '' };

    handleChange(null, 'type', value);
  };

  const handleClientSearch = async (searchTerm: string) => {
    const clients = await carecoApi?.getClients(currentRoster!.id, 1, searchTerm);

    return clients?.data.clients.map((client): Value => ({
      label: `${DisplayHelpers.formatName(client.firstName, client.middleName, client.lastName)}
        - DOB ${DisplayHelpers.formatDateOfBirthWithAge(client.dateOfBirth)}`,
      value: client.patientId,
    })) ?? [];
  };

  const typeOptions = [
    {
      label: 'DTRN Social Care',
      level: Helpers.getAppDataRoleDetails().levels.SSO,
      permission: Helpers.getAppDataRoleDetails().permissions.FINDHELP,
    },
    {
      label: 'DTRN eReferral',
      level: Helpers.getAppDataRoleDetails().levels.SSO,
      permission: Helpers.getAppDataRoleDetails().permissions.OB_REFERRALS,
    },
    {
      label: 'Crisis',
      level: Helpers.getAppDataRoleDetails().levels.SSO,
      permission: Helpers.getAppDataRoleDetails().permissions.OB_CRISIS,
    },
    {
      label: 'DTRN RideShare',
      level: Helpers.getAppDataRoleDetails().levels.SSO,
      permission: Helpers.getAppDataRoleDetails().permissions.ROUNDTRIP,
    },
  ].filter((option) =>
    PermissionsHelpers.canForUserDataRole(currentUser!, option.level, option.permission)
  ).map((option): Value => ({
    label: option.label,
    value: option.permission,
  }));

  const modalButtons = [
    <Button
      key="submit"
      styleType={StyleType.PRIMARY}
      disabled={!isValid()}
      onClick={handleSubmit}
    >
      Create
    </Button>,
    <Button key="cancel" styleType={StyleType.TERTIARY} onClick={props.hideModal}>
      Cancel
    </Button>,
  ];

  return (
    <Modal headerText="Create Referral" buttons={modalButtons} className={css({ width: '70rem' })}>
      <DropdownList
        id="referral-type"
        label="Type"
        items={typeOptions}
        value={form.type?.value}
        handleChange={handleTypeChange}
        error={!form.type?.valid?.valid && form.type?.valid?.message}
        errorMessage={form.type?.valid?.message}
        required
        isWithinModal
      />
      {!isOpenBedsSelected && !roundTripSelected &&(
        <DropdownList
          id="referral-client"
          label="Client"
          value={form.client?.value}
          handleChange={(value) => handleChange(null, 'client', value)}
          handleSearch={handleClientSearch}
          placeholder="Search by client name"
          error={!form.client?.valid?.valid && form.client?.valid?.message}
          errorMessage={form.client?.valid?.message}
          required
          isWithinModal
        />
      )}
    </Modal>
  );
}
