import {
  NameWrapper,
  RoundButton,
  RoundIconWrapper,
  StyledFileIcon,
  StyledSemiBoldTypography,
  StyledUploadButton,
  StyledUploadIcon,
  UploadButtonWrapper
} from "./FormsTab.styles";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {colors} from "patient-ping-remedy/packages/theme";
import React, {useCallback, useContext, useEffect, useState} from "react";
import FormUploadModal from "./FormUploadModal";
import PermissionsHelpers from "../../../../../helpers/permissions_helpers";
import Helpers from "../../../../../helpers/helpers";
import {useAuthStore} from "../../../../../store/auth_store";
import SortableTable, {TableColumn, TableData} from "../../../../common/SortableTable";
import Icon from "patient-ping-remedy/packages/icon";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";
import {useRosterStore} from "../../../../../store/roster_store";
import {Form} from "../../../../../api/dto/client-profile";
import FormDetailsDrawer from "./FormDetailsDrawer";
import {HintTypography} from "patient-ping-remedy/packages/typography";
import DisplayHelpers from "../../../../../helpers/display_helpers";
import AvatarDetails from "../../../../common/AvatarDetails";
import ContextMenu from "patient-ping-remedy/packages/context-menu";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {GENERICERROR} from "../../manual_encounter/constants/BannerMessages";
import {useAlertStore} from "../../../../../store/alert_store";
import {AxiosError} from "axios";
import {
  ClientPageTab,
  FormExportMethod,
  FormOrigin,
  FormStatus,
  MixpanelClientProfilePageViewedEvent,
  MixpanelEventType,
  MixpanelFormEvent,
  TableName,
  MixpanelFormUpload
} from "../../../../../api/dto/mixpanel";
import {getCurrentGroupDetails, getMixpanelEventProperties} from "../../../../../helpers/mixpanel_helpers";

type Props = {
  patientId: string
}

export type SlidePaneFormProps = {
  form: Form;
  formPdfUrl?: string;
}

const FormsTab = (props: Props) => {
  const [showFormUploadModal, setShowFormUploadModal] = useState<boolean>(false);
  const { currentUser } = useAuthStore();
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [slidePaneOpen, setSlidePaneOpen] = useState<boolean>(false);
  const [slidePaneForm, setSlidePaneForm] = useState<SlidePaneFormProps>();
  const [slidePaneError, setSlidePaneError] = useState<Error | null>(null);
  const { carecoApi } = useContext(CarecoApiContext);
  const { currentRoster } = useRosterStore();
  const [resetTableFilters, setResetTableFilters] = useState<boolean>(false);
  const { addAlert } = useAlertStore();
  const queryClient = useQueryClient();

  const { data: forms, isLoading, error } = useQuery({
    queryKey: ['forms', currentRoster?.id, props.patientId],
    queryFn: () => carecoApi?.getForms(currentRoster!.id, props.patientId),
    enabled: !!carecoApi
  });

  const deleteFormMutation = useMutation({
    mutationFn: async (formId: number) => {
      return carecoApi?.deleteForm(formId);
    },
    onSuccess: (_, formId) => {
      queryClient.invalidateQueries({queryKey: ['forms', currentRoster?.id, props.patientId]}).then(r => console.log(r));
      addAlert({
        content: 'Form deleted successfully.',
        type: ToastType.SUCCESS,
      });

      const deletedForm = forms!.find((form: Form) => form.id === formId)!;
      postMixpanelEvent(deletedForm, MixpanelEventType.BIH_FORM_DELETED, undefined, undefined);
    },
    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to delete form. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    },
  });

  const getUrlObject = (form: Form, response: string) => {
    const arrayBuffer = Helpers.base64ToArrayBuffer(response);
    const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
    return {
      form,
      formPdfUrl: URL.createObjectURL(blob),
      size: Helpers.formatBits(blob.size)
    };
  };

  const closeSlidePane = () => {
    setSlidePaneOpen(false);
    setSlidePaneForm(undefined);
    setSlidePaneError(null);
  };

  const showSlidePaneForm = (form: Form) => {
    setSlidePaneOpen(true);
    carecoApi?.getFormUpload(currentRoster!.id, form.client.roster.id, form.client.publicPatientId, form.id)
      .then((response) => {
        setSlidePaneForm(getUrlObject(form, response));
      })
      .catch((error) => {
        setSlidePaneError(error);
      });
  };

  const printPDF = async (form: Form) => {
    carecoApi?.getFormUpload(currentRoster!.id, form.client.roster.id, form.client.publicPatientId, form.id)
      .then((response) => {
        const urlObject = getUrlObject(form, response);
        let pdfUrl = urlObject.formPdfUrl;
        let iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = pdfUrl;
        iframe.id = 'pdf';
        iframe.onload = function() {
          iframe.focus();
          iframe.contentWindow?.print();
          const removeIframe = () => {
            iframe.parentElement?.removeChild(iframe);
            window.removeEventListener('focus', removeIframe);
          };
          window.addEventListener('focus', removeIframe);
        };

        postMixpanelEvent(form, MixpanelEventType.BIH_FORM_EXPORTED, FormExportMethod.PRINT, urlObject.size);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const downloadPdf = async (form: Form) => {
    carecoApi?.getFormUpload(currentRoster!.id, form.client.roster.id, form.client.publicPatientId, form.id)
      .then((response) => {
        const urlObject = getUrlObject(form, response);
        let pdfUrl = urlObject.formPdfUrl;

        let anchorTag = document.createElement('a');
        anchorTag.href = pdfUrl;
        anchorTag.target = '_blank';
        anchorTag.download = `${form.form_upload ? form.form_upload.name : form.form_definition.name}.pdf`;
        document.body.appendChild(anchorTag);
        anchorTag.click();
        document.body.removeChild(anchorTag);

        postMixpanelEvent(form, MixpanelEventType.BIH_FORM_EXPORTED, FormExportMethod.DOWNLOAD, urlObject.size);
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };

  function postMixpanelEvent(form: Form, eventName: MixpanelEventType, exportMethod?: FormExportMethod, fileSize?: string) {
    const mixpanelEvent : MixpanelFormEvent = {
      ...getMixpanelEventProperties(eventName),
      ...getCurrentGroupDetails(currentRoster!),
      clientId: props.patientId,
      formId: form.id,
      formName: form.form_upload?.name ?? '',
      formType: form.form_upload?.form_type ?? '',
      fileSize: fileSize,
      exportMethod: exportMethod,
      formStatus: FormStatus.COMPLETED,
      formOrigin: FormOrigin.UPLOADED
    };

    carecoApi?.postMixpanelEvent(mixpanelEvent);
  }

  const mapTableFormsData = useCallback((form: Form) : TableData => {
    const menuItems = [
      {
        action: () => printPDF(form),
        label: 'Print',
      },
      {
        action: () => downloadPdf(form),
        label: 'Download',
      }
    ];

    if(form.created_by.id === currentUser?.id &&
      PermissionsHelpers.canForUserFeatureRoleRoster(
        currentUser!,
        currentRoster!,
        Helpers.getAppFeatureRoleDetails().levels.ACT,
        Helpers.getAppFeatureRoleDetails().permissions.FORMS)
    ) {
      menuItems.push({
        action: async () => deleteFormMutation.mutate(form.id),
        label: 'Delete',
      });
    }

    return {
      name: {
        tableHeader: 'Name',
        key: 'name',
        type: 'string',
        value: form.form_upload ? form.form_upload.name : '',
        displayValue: (
          <NameWrapper>
            <Button
              onClick={() => showSlidePaneForm(form)}
              styleType={StyleType.TERTIARY}>
              { form.form_upload ? form.form_upload.name : '' }
            </Button>
            <RoundButton styleType={StyleType.TERTIARY} onClick={() => showSlidePaneForm(form)}>
              <StyledFileIcon iconClass={'paperclip'} color={colors.gray4} weight={'far'}/>
            </RoundButton>
          </NameWrapper>
        )
      },
      type: {
        tableHeader: 'Type',
        key: 'type',
        type: 'string',
        value: form.form_upload ? form.form_upload.form_type : form.form_definition.form_type,
        displayValue: form.form_upload ? form.form_upload.form_type : form.form_definition.form_type
      },
      score: {
        tableHeader: 'Score',
        key: 'score',
        type: 'string',
        value: form.score ?? '',
        displayValue: form.score ?? ''
      },
      status: {
        tableHeader: 'Status',
        key: 'status',
        type: 'date',
        value: form.completed_at ? form.completed_at : form.saved_at,
        displayValue: form.completed_at ?
          <>
            <div>Completed</div>
            <div>
              <HintTypography>
                { form.form_upload ? DisplayHelpers.formatDate(form.completed_at)
                  : DisplayHelpers.formatDateTimeShort(form.completed_at) }
              </HintTypography>
            </div>
          </>
          : <>
            <div>In Progress</div>
            <div>
              <HintTypography>
                { form.form_upload ? DisplayHelpers.formatDate(form.saved_at)
                  : DisplayHelpers.formatDateTimeShort(form.saved_at) }
              </HintTypography>
            </div>
          </>
      },
      completed_by: {
        tableHeader: 'Completed by',
        key: 'completed_by',
        type: 'string',
        value: `${form.created_by.first_name} ${form.created_by.last_name}`,
        displayValue: <AvatarDetails user={form.created_by} roster={form.client.roster} />,
      },
      actions: {
        tableHeader: '',
        key: 'actions',
        type: 'custom',
        value: '',
        displayValue: (
          <ContextMenu
            menuItems={ menuItems.length > 0
              ? menuItems
              : [{action: () => {}, label: 'No actions available'}]}
            menuTestId="form-context-menu"
            position="bottom"
            triggerTestId="form-call-to-action"
          >
            <RoundIconWrapper>
              <Icon iconClass={'ellipsis-h'} color={colors.gray4} weight={'far'} />
            </RoundIconWrapper>
          </ContextMenu>
        )
      }
    };
  }, []);

  useEffect(() => {
    if(forms) {
      setTableData(
        forms.map((form: Form) => {
          return mapTableFormsData(form);
        })
      );
    }
  }, [forms, mapTableFormsData]);

  useEffect(() => {
    if ( currentRoster && props.patientId ) {
      const mixpanelEvent: MixpanelClientProfilePageViewedEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_CLIENT_PROFILE_VIEWED),
        ...getCurrentGroupDetails(currentRoster),
        clientId: props.patientId,
        tabViewed: ClientPageTab.FORMS,
        encounterIds: {}
      };
      carecoApi?.postMixpanelEvent(mixpanelEvent);
    }
  }, [currentRoster, props.patientId]);

  useEffect(() => {
    if(showFormUploadModal && currentRoster) {
      const mixpanelEvent: MixpanelFormUpload = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_FORM_UPLOAD_MODAL_OPENED),
        ...getCurrentGroupDetails(currentRoster),
        clientId: props.patientId
      };
      carecoApi?.postMixpanelEvent(mixpanelEvent);
    }
  }, [showFormUploadModal, props.patientId, currentRoster]);

  return (
    <>
      <UploadButtonWrapper>
        {
          PermissionsHelpers.canForUserFeatureRoleRoster(
            currentUser!,
            currentRoster!,
            Helpers.getAppFeatureRoleDetails().levels.ACT,
            Helpers.getAppFeatureRoleDetails().permissions.FORMS) &&
          <>
            <StyledUploadButton onClick={() => setShowFormUploadModal(true)} styleType={StyleType.SECONDARY}>
              <StyledUploadIcon iconClass={'cloud-upload'} color={colors.tertiary2} weight={'far'} />
              <StyledSemiBoldTypography>Upload</StyledSemiBoldTypography>
            </StyledUploadButton>

            <FormUploadModal
              showFormUploadModal={showFormUploadModal}
              setShowFormUploadModal={setShowFormUploadModal}
              patientId={props.patientId}
            />
          </>
        }
      </UploadButtonWrapper>

      {
        PermissionsHelpers.canForUserFeatureRoleRoster(
          currentUser!,
          currentRoster!,
          Helpers.getAppFeatureRoleDetails().levels.VIEW,
          Helpers.getAppFeatureRoleDetails().permissions.FORMS) &&
          <SortableTable id={'forms-table'}
            loading={isLoading}
            loadError={error}
            data={tableData}
            header={Object.values(tableData[0] ?? [])?.map((value: TableColumn) => value.tableHeader)}
            searchColumns={[
              'completed_by',
              'name'
            ]}
            sortableColumns={tableData[0] ?
              [tableData[0]['name'], tableData[0]['status'], tableData[0]['type']]: []}
            searchPlaceholder={'Search by user name or form name'}
            resetTableFilters={resetTableFilters}
            setResetTableFilters={setResetTableFilters}
            tabName={TableName.FORMS}
          />
      }

      { forms &&
        <FormDetailsDrawer
          isOpen={slidePaneOpen}
          close={closeSlidePane}
          form={slidePaneForm}
          error={slidePaneError}
        />
      }
    </>
  );
};

export default FormsTab;
