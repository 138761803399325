import React from 'react';
import {useAuthStore} from "../../store/auth_store";
import Helpers from "../../helpers/helpers";
import PermissionsHelpers from "../../helpers/permissions_helpers";
import ApplicationMenu from "./ApplicationMenu";
import {NavItem as RemedyNavItem, NavRail as RemedyNavRail} from "patient-ping-remedy/packages/navigation-rail";
import {router} from "../../router";

const NavRail = () => {
  const { currentUser } = useAuthStore((state) => ({ currentUser: state.currentUser }));

  if(!currentUser) return null;

  return (
    <RemedyNavRail
      onItemClick={(route) => router.navigate(route)}
      appMenu={<ApplicationMenu />}
    >
      <RemedyNavItem
        text={'Clients'}
        iconClass={'user'}
        route={'/clients'}
      />
      {
        PermissionsHelpers.canForUserFeatureRole(currentUser,
          Helpers.getAppFeatureRoleDetails().levels.VIEW,
          Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS
        ) &&
        <RemedyNavItem
          text={'Admin'}
          route={'/admin'}
          iconClass={'cogs'}
        />
      }
      {
        currentUser?.role?.toUpperCase().includes(Helpers.getAppRoles().BAMBOO_ADMIN) &&
        <RemedyNavItem
          text={'Settings'}
          iconClass={'cog'}
          route={'/app_settings'}
        />
      }
      {
        currentUser?.role?.toUpperCase().includes(Helpers.getAppRoles().BAMBOO_ADMIN) &&
        <RemedyNavItem
          text={'Tools'}
          iconClass={'tools'}
          route={'/tools'}
        />
      }
    </RemedyNavRail>
  );
};

export default React.memo(NavRail);
