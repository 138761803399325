import React, {useContext, useEffect} from "react";
import {CarecoApiContext} from "../../../../../app-context/careco-api-context";
import {useRosterStore} from "../../../../../store/roster_store";
import {ClientProfile} from "../../../../../api/dto/client-profile";
import {useQuery} from "@tanstack/react-query";
import {StyledIframe} from "./PdmpTab.styles";
import Loading from "../../../../common/Loading";
import Header from "../../../../common/pages/Header";
import {AxiosError, HttpStatusCode} from "axios";
import PdmpError from "./PdmpError";
import {ClientPageTab, MixpanelClientProfilePageViewedEvent, MixpanelEventType} from "../../../../../api/dto/mixpanel";
import {getCurrentGroupDetails, getMixpanelEventProperties} from "../../../../../helpers/mixpanel_helpers";

type Props = {
  patient: ClientProfile;
}

const PdmpTab = (props: Props) => {
  const patientId = props.patient?.patient?.patientId;
  const { carecoApi } = useContext(CarecoApiContext);
  const { currentRoster } = useRosterStore();

  const { data: pdmpPatientResponse, isLoading: pdmpPatientDataLoading, error: pdmpPatientError } = useQuery({
    queryKey: ['pdmp', 'patientResponse', patientId],
    queryFn: () => carecoApi?.getPatientRequest(
      currentRoster!.id,
      patientId!
    ),
    enabled: !!carecoApi && !!currentRoster && !!patientId
  });

  const pdmpResponse = useQuery({
    queryKey: ['pdmp', 'reportResponse', patientId],
    queryFn: () => carecoApi?.getReportRequest(
      currentRoster!.id,
      patientId!,
      pdmpPatientResponse!.reportURL
    ),
    enabled: !!carecoApi && !!currentRoster && !!patientId && !!pdmpPatientResponse?.reportURL,
    staleTime: process.env.REACT_APP_PDMP_REPORT_STALE_TIME_MINUTE * 60 * 1000, // 15 minutes
    retry: (failureCount, error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.Gone && failureCount < 3) {
          return true;
        }
      }
      return false;
    }
  });

  useEffect(() => {
    const handleIframeHeightChange = (event: MessageEvent) => {
      if(event.data['height']) {
        const iframe = document.querySelector('iframe');
        if(iframe) {
          let newHeight = event.data['height'] + 50; // add extra room for padding to prevent scrollbars
          iframe.style.height = `${newHeight}px`;
        }
      }
    };

    window.addEventListener('message', handleIframeHeightChange);
    return () => {
      window.removeEventListener('message', handleIframeHeightChange);
    };
  }, []);

  const isLoading = pdmpPatientDataLoading || pdmpResponse.isLoading;
  const error = (pdmpPatientError || pdmpResponse.error) as AxiosError;
  const hasData = !!pdmpPatientResponse && !!pdmpResponse.data;

  useEffect(() => {
    if (currentRoster && props.patient?.patient?.patientId) {
      const mixpanelEvent: MixpanelClientProfilePageViewedEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_CLIENT_PROFILE_VIEWED),
        ...getCurrentGroupDetails(currentRoster),
        clientId: props.patient.patient.patientId,
        tabViewed: ClientPageTab.PDMP,
        encounterIds: {}
      };
      carecoApi?.postMixpanelEvent(mixpanelEvent);
    }
  }, []);

  if(isLoading) return <Loading iconSize={'4x'}/>;
  if(error || !hasData) return <PdmpError error={error} isTile={false} />;

  return (
    <>
      <Header margin={'3rem'}>Prescription monitoring program</Header>
      <StyledIframe data-testid={'pdmp-report-html'} src={pdmpResponse.data}/>
    </>
  );
};

export default PdmpTab;
