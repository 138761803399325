import React, { useState } from 'react';
import Spacer from 'patient-ping-remedy/packages/spacer';
import ExpandCollapseArrow from 'patient-ping-remedy/packages/expand-collapse-arrow';
import {
  Container,
  ExpandMoreStyled,
  ExpandMoreContentStyled,
} from './FormExpandMoreButton.styles';
import { colors } from 'patient-ping-remedy/packages/theme';


const FormExpandMoreButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Container>
        <ExpandMoreStyled
          key={'expand-more-button'}
          onClick={() => setIsOpen(!isOpen)}
        >
          Details
          <Spacer itemWidth={'0.5rem'}></Spacer>
          <ExpandCollapseArrow
            isOpen={!isOpen}
            iconSize={'lg'}
            iconColor={colors.primary1}
          />
        </ExpandMoreStyled>
        {isOpen && (
          <ExpandMoreContentStyled>
                  Discharge Instructions are documents that clients receive upon discharge that
                  contain written instructions covering topics such as: Activities permitted at home
                  list of medications, diet, follow-up appointments, etc.
          </ExpandMoreContentStyled>
        )}
      </Container>
    </>
  );
};

export default FormExpandMoreButton;
