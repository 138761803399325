import React, {useMemo} from 'react';
import {SDOHItem} from '../../../../../../api/dto/sdoh';
import {
  EventContainer,
  EventContainerEvents,
  EventCurrentStatus,
  EventDetails,
  EventLowerSection1,
  EventLowerSection1Content,
  EventSectionHeader,
  EventType,
  ExpandEventDetailsSectionDetails,
  TimelineContentWrapper,
  TimelineDate,
  TimelineEventWrapper
} from '../../../../../common/timeline/Timeline.styles';
import MainEventIcon from '../../../../../common/timeline/MainEventIcon';
import DetailsSection from '../../../../../common/timeline/DetailsSection';
import LOSIndicator from '../../../../../common/timeline/LOSIndicator';
import ExpandMoreButton from '../../../../../common/timeline/ExpandMoreButton';
import EventStatus from '../../../../../common/timeline/EventStatus';
import referralIcon from '../../../../../../assets/icons/Custom-Icon-Referral.svg';
import {SDOHStatus, SDOHStatuses} from './SDOHCard.constants';
import {EventStatusWrapper, ExpandMoreButtonWrapper} from './SDOHCard.styles';
import SDOHTimeline from './SDOHTimeline';
import SDOHOfficeAddress from './SDOHOfficeAddress';
import DisplayHelpers from '../../../../../../helpers/display_helpers';
import Helpers from '../../../../../../helpers/helpers';
import Part2Disclaimer from "../../../../../common/Part2Disclaimer";
import {StyleType} from "patient-ping-remedy/packages/button";

type Props = {
  id: string;
  event: SDOHItem;
  isPart2?: boolean,
}

export default function SDOHCard(props: Props) {
  const sortedEvents = useMemo(() => {
    return props.event.events.sort((a, b) =>
      new Date(b.eventDateTime).getTime() - new Date(a.eventDateTime).getTime()
    );
  }, [props.event.events]);

  const firstEvent = useMemo(() => sortedEvents.at(-1)!, [sortedEvents]);
  const latestEvent = useMemo(() => sortedEvents.at(0)!, [sortedEvents]);

  const latestEventStatus = useMemo(() => {
    return SDOHStatus[latestEvent.derivedStatus];
  }, [latestEvent]);

  const active = useMemo(() => {
    return [SDOHStatuses.NEW_REFERRAL, SDOHStatuses.PENDING].includes(latestEvent.derivedStatus);
  }, [latestEvent]);

  const eventYear = useMemo(() => {
    return new Date(firstEvent.eventDateTime).getFullYear();
  }, [firstEvent]);

  const eventDate = useMemo(() => {
    return DisplayHelpers.getDateMonthDay(firstEvent.eventDateTime);
  }, [firstEvent]);

  const lengthOfReferral = useMemo(() => {
    if (active) {
      return 'Ongoing:';
    } else {
      const startDate = new Date(firstEvent.eventDateTime);
      const endDate = new Date(latestEvent.eventDateTime);
      const days = Helpers.calculateTimeDifference(startDate, endDate);

      return `Time ${days} ${days === 1 ? 'day' : 'days'}:`;
    }
  }, [active, firstEvent, latestEvent]);

  const offices = useMemo(() => {
    return props.event.program.offices.filter((office) => office.address.address1);
  }, [props.event.program.offices]);

  // Comment from only first event is used, because all comments for a single referral have the same value
  const comment = useMemo(() => {
    const trimmedComment = DisplayHelpers.removeUnnecessaryWhitespace(firstEvent.comment);
    const comment = DisplayHelpers.capitalizeFirstLetter(trimmedComment);

    return comment.at(-1) === '.' ? comment : `${comment}.`;
  }, [firstEvent]);

  return (
    <div id={props.id}>
      <TimelineEventWrapper>
        <TimelineDate>
          <span className="timeline__event__date-year">{eventYear}</span>
          <span className="timeline__event__date-date">{eventDate}</span>
        </TimelineDate>
        <TimelineContentWrapper>
          <EventContainer active={active}>
            <MainEventIcon active={active} iconAlt="referral-icon" iconSvg={referralIcon} />
            <EventDetails>
              <EventType>SDOH referral</EventType>
              <DetailsSection detailsHeaderText="Recieving contact" hasDetails={true}>
                <EventStatusWrapper>
                  <EventStatus
                    iconMessage={latestEventStatus.displayName}
                    iconClass={latestEventStatus.iconClass}
                  />
                </EventStatusWrapper>
                <div>{props.event.program.name}</div>
                {offices.at(0) && (
                  <SDOHOfficeAddress office={offices.at(0)!} />
                )}
                {offices.length > 1 && (
                  <ExpandMoreButtonWrapper>
                    <ExpandMoreButton
                      buttonText="Additional addresses"
                      expandItems={offices.slice(1).map((office) => (
                        <ExpandEventDetailsSectionDetails>
                          <SDOHOfficeAddress office={office} />
                        </ExpandEventDetailsSectionDetails>
                      ))}
                      active={active}
                      useExpandHideText={false}
                      renderBelow
                    />
                  </ExpandMoreButtonWrapper>
                )}
              </DetailsSection>
            </EventDetails>
            <EventContainerEvents active={active}>
              <EventCurrentStatus>
                <LOSIndicator
                  message={lengthOfReferral}
                  iconMessage={latestEventStatus.displayName}
                  iconClass={latestEventStatus.iconClass}
                  active={active}
                />
              </EventCurrentStatus>
              <SDOHTimeline
                events={sortedEvents}
                program={props.event.program}
                sender={props.event.sender}
                roster={props.event.roster}
                active={active}
              />
              {comment.length > 1 && (
                <EventLowerSection1 inline>
                  <EventSectionHeader inline>Comments:</EventSectionHeader>
                  <EventLowerSection1Content inline>
                    {comment.slice(0, 150)}
                    {comment.length > 150 && (
                      <ExpandMoreButton
                        buttonText={(isOpen) => isOpen ? 'show less' : 'show more'}
                        expandItems={[comment.slice(150)]}
                        active={active}
                        useExpandHideText={false}
                        inline
                      />
                    )}
                  </EventLowerSection1Content>
                </EventLowerSection1>
              )}
            </EventContainerEvents>
            { props.isPart2 && <Part2Disclaimer styleType={active ? StyleType.PRIMARY : StyleType.TERTIARY} /> }
          </EventContainer>
        </TimelineContentWrapper>
      </TimelineEventWrapper>
    </div>
  );
}
