import styled from '@emotion/styled';
import {LabelFormFieldTypography, SmallTypography} from "patient-ping-remedy/packages/typography";
import {colors, sizes} from "patient-ping-remedy/packages/theme";
import Button from "patient-ping-remedy/packages/button";
import RadioButton from "patient-ping-remedy/packages/radio-button";
import Icon from "patient-ping-remedy/packages/icon";
import Input from "patient-ping-remedy/packages/input";
import DropdownList from "patient-ping-remedy/packages/dropdown-list";
import {css} from "@emotion/react";

export const StyledRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 28rem;
  }
`;

export const StyledLabelFormFieldTypography = styled(LabelFormFieldTypography)`
  display: block;
`;

export const StyledIconWrapper = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.purple2};
  border-radius: 50%;
  height: 3.2rem;
  width: 3.2rem;
  margin-right: ${sizes.xSmall};
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

export const StyledRadioButton = styled(RadioButton)`
  margin-top: 1.8rem;
`;

export const StyledIconClose = styled(Icon)`
  font-size: 1.4rem;
  cursor: pointer;
  position: absolute;
  top: ${sizes.small};
  right: -${sizes.small};
`;

export const StyledControlsContainer = styled.div((showOtherInsuranceInput) => (
  css`
  display: flex;
  &.radioDropdown {
    max-height: ${showOtherInsuranceInput ? '17rem': '7rem'};
  }
  &.inputContainer {
    position: relative;
  }
`));

export const StyledIdInput = styled(Input)`
  flex: 1;
  margin-top: 0.1rem;
  & > label {
    display: none;
  }
    
  & > input {
    border-radius: 0.3rem;
  }
`;

export const StyledOtherNameInput = styled(Input)`
  margin-top: -${sizes.small};
`;

export const StyledDropdownContainer = styled('div')`
  flex: 1;
`;

export const StyledIdDropdownList = styled(DropdownList)`
  flex: 1;
  & > label {
    display: none;
  }
`;

export const StyledSmallTypography = styled(SmallTypography)`
  position: relative;
  bottom: 2.5rem;
`;

export const StyledDisclaimerText = styled('p')`
  margin-top: ${sizes.small};
  color: ${colors.yellow5};
  max-width: 60rem;
  font-size: 1.5rem;
`;
