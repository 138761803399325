import styled from '@emotion/styled';
import {fontSize} from "patient-ping-remedy/packages/theme";
import Spinner from "patient-ping-remedy/packages/spinner";

export const ScanRosterHeader = styled('div')`
  font-size: ${fontSize.standard};
`;

export const StyledSpinner = styled(Spinner)`
  margin-left: 10px;
  margin-right: 10px;
`;

export const SpinnerHint = styled('span')`
  font-size: ${fontSize.small};
`;
